import { post } from '../service_config/http.service';

export function getCurrentUser() {
  /**
   * UserContext is a context that stores the user data.
   */
  return JSON.parse(localStorage.getItem('UserData'))?.user;
}

export const genrateOtp = async (data) => {
  try {
    const res = await post(`auth/send-otp`, data);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};
export const verifyOtp = async (data) => {
  try {
    const res = await post(`auth/verify-otp`, data);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

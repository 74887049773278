/* eslint-disable prettier/prettier */
import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import BaeLeftDrawer from '../components/Bae/BaeLeftDrawer';
import Hamburger from 'hamburger-react';
import ProfilePopupButton from '../components/Bae/BaeProfilePopupButton';

function ResponsiveDrawer(props) {

  const [isDrawerVisible, setDrawerVisible] = React.useState(false);
  const drawerRef = React.useRef(null);
  const [isMobile, setIsMobile] = React.useState(false);

  const contentRef = React.useRef(null);

  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setDrawerVisible(false);
    }
  };

  React.useEffect(() => {
    if (!isMobile) return
    if (isDrawerVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDrawerVisible]);

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, [window.innerWidth]);


  React.useEffect(() => {
    if (contentRef.current) {
      // Try multiple scrolling methods
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      contentRef.current.scrollTop = 0;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="flex">
      {/* Left Drawer */}
      <div
        ref={drawerRef}
        className={`no-scrollbar shadow-md fixed md:sticky top-0 left-0 z-50 h-screen p-4 overflow-y-auto bg-white w-72 min-w-72 max-w-72 transition-transform duration-300 ${isDrawerVisible
          ? 'translate-x-0'
          : '-translate-x-full md:translate-x-0'
          }`}
      >
        <BaeLeftDrawer {...(isMobile && { toggleDrawer })} />
      </div>

      {/* Main Content */}
      <div className={`flex-1 flex flex-col transition-all duration-300 `}>
        {/* Header Section */}
        <div className={` bg-baeonn-700 min-h-16 text-white flex items-center md:justify-end fixed md:sticky top-0 w-full z-40`}>
          {/* on mobile view */}

          <div className="p-2 md:hidden block z-10">
            <Hamburger toggled={isDrawerVisible} toggle={toggleDrawer} />
          </div>

          {/* on desktop view */}
          <div className="p-4 md:block hidden z-10">
            <ProfilePopupButton />
          </div>

        </div>

        {/* Scrollable Content */}
        <div ref={contentRef} className="flex-1 overflow-y-auto mt-16 md:mt-0">
          <div id="pageLoader">
            <CircularProgress />
          </div>
          <div className="md:p-6 p-4 md:m-6 bg-white md:border md:rounded-md md:shadow-md">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsiveDrawer;

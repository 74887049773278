import { Link } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function TermsAndConditions() {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <p>
          Baeonn Breach Terms:
          <Link
            target="_blank"
            href="https://baeonn-terms-and-conditions.s3.ap-south-1.amazonaws.com/Breach+Terms.docx.pdf"
          >
            Click Here
          </Link>
        </p>
        <p>
          Baeonn Membership Agreement:{' '}
          <Link
            target="_blank"
            href="https://baeonn-terms-and-conditions.s3.ap-south-1.amazonaws.com/Membership+Agreement.pdf"
          >
            Click Here
          </Link>
        </p>
        <p>
          Baeonn Payment and Transaction Service Terms:{' '}
          <Link
            target="_blank"
            href="https://baeonn-terms-and-conditions.s3.ap-south-1.amazonaws.com/Payment+and+Transaction+Service+Terms.docx.pdf"
          >
            Click Here
          </Link>
        </p>
        <p>
          Baeonn Privacy Policy BAEON:{' '}
          <Link
            target="_blank"
            href="https://baeonn-terms-and-conditions.s3.ap-south-1.amazonaws.com/Privacy+Policy+BAEON.docx.pdf"
          >
            Click Here
          </Link>
        </p>
      </div>
    </>
  );
}

import React, { createContext, useState, useEffect } from 'react';

const BaseContextConfig = {
  disableSidebarMenuButtons: false,
};

// Create a context
const AppContext = createContext();

// Create a provider component
const AppProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    // Get initial state from localStorage if available
    const savedData = localStorage.getItem('appData');
    return savedData ? JSON.parse(savedData) : BaseContextConfig;
  });

  useEffect(() => {
    // Save data to localStorage whenever it changes
    localStorage.setItem('appData', JSON.stringify(data));
  }, [data]);

  const updateData = (newData) => {
    setData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <AppContext.Provider value={{ data, updateData }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };

import SideBar from '../sideBar/sidebar';

const BaeLeftDrawer = ({ toggleDrawer }) => {
  return (
    <div>
      <div id="drawer-disable-body-scrolling">
        <div
          id="drawer-disable-body-scrolling"
          aria-labelledby="drawer-disable-body-scrolling-label"
        >
          {toggleDrawer && (
            <button
              type="button"
              data-drawer-hide="drawer-disable-body-scrolling"
              aria-controls="drawer-disable-body-scrolling"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center  "
              onClick={toggleDrawer}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close menu</span>
            </button>
          )}
          <SideBar />
        </div>
      </div>
    </div>
  );
};

export default BaeLeftDrawer;

import { Button, makeStyles } from '@material-ui/core';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
// Import axios for making API requests
import * as React from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import WrapperCard from '../../../components/wrapperCard/wrapperCard';
import MainLayout from '../../../layout/mainLayout';
import {
  deleteSendInviteStore,
  getInviteStore,
  getStoreDetail,
  getStoreDetailforManager,
  reSendInviteStore,
} from '../../../service_config/admin.service';
import { BUSINESSOWNER } from '../../../utilities/constants';
import StoreCard from './StoreCard';
import { UserContext } from '../../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  lable: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#57748a',
  },
  card: {
    maxWidth: '1000px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    margin: '0px auto',
    marginTop: '100px',
    borderRadius: '5px',
    padding: '20px',
  },
  listcard: {
    maxWidth: '1000px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    background: '#fbfcfa',
    margin: '0px auto',
    marginTop: '10px',
    borderRadius: '20px',
  },
  cancel: {
    background: '#152894',
    color: '#f7f8fa',
    fontWeight: '700',
    '&:hover': {
      background: '#152894',
      color: '#f7f8fa',
    },
  },
  inputfile: {
    display: 'none',
  },
  upload: {
    background: '#f0f3f5',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  icon: {
    transform: 'rotate(180deg)',
  },
  grid: {
    padding: '25px 0',
  },
  input: {
    border: 'none',
    boxShadow: '0 3px 5px rgb(0 0 0 / 0.1)',
    background: 'white',
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
  },
  radio: {
    '&$checked': {
      color: '#4B8DF8',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 'wrap',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  checked: {},
  edit: {},
  companyLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

function Store(props) {
  const [value, setValue] = React.useState('1');
  const [deleted, setDeleted] = React.useState(false);
  const {
    user: { user },
  } = React.useContext(UserContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const stores = useSelector((state) => state.storeDetail.stores);
  const invites = useSelector((state) => state.storeDetail.invites);
  const classes = useStyles();

  React.useEffect(() => {
    if (user.role.name === BUSINESSOWNER) {
      getStoreDetail();
    } else {
      getStoreDetailforManager({ id: user.storeId });
    }

    getInviteStore();
  }, [deleted]);

  const handleAction = async (name, value) => {
    switch (name) {
      case 'delete':
        const res = await deleteSendInviteStore({
          body: {
            invitationIds: [value],
          },
        });

        if (!res.error) {
          toast.success(res.msg);
          setDeleted(!deleted);
        } else {
          toast.error(res.msg);
        }

        break;
      case 'reSend':
        const response = await reSendInviteStore({
          body: {
            emails: [value],
          },
        });

        if (!response.error) {
          toast.success(response.msg);
        } else {
          toast.error(response.msg);
        }
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line prettier/prettier
  const handleAssignQR = (store) => { };

  return (
    <MainLayout>
      <div>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="STORES" value="1" />
                {user.role.name === BUSINESSOWNER && (
                  <Tab label="INVITES" value="2" />
                )}
              </TabList>
            </Box>
            <TabPanel value="1">
              <>
                <Typography
                  variant="h6"
                  display="block"
                  sx={{
                    lineHeight: '1rem',
                    fontWeight: 'bold',
                    color: '#1c1c1c',
                  }}
                  className={classes.lable}
                >
                  STORE DETAILS
                </Typography>

                {stores?.length > 0 ? (
                  stores?.map((store, index) => {
                    return (
                      <div key={index} className="mt-4">
                        <StoreCard store={store} />
                      </div>
                    );
                  })
                ) : (
                  <Box
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      width: '100%',
                    }}
                  >
                    <Chip
                      label="No data found"
                      color="primary"
                      variant="outlined"
                    />
                  </Box>
                )}
              </>
            </TabPanel>
            <TabPanel value="2">
              <Typography
                variant="h6"
                display="block"
                sx={{
                  lineHeight: '1rem',
                  fontWeight: 'bold',
                  color: '#1c1c1c',
                  marginBottom: '30px',
                }}
                className={classes.lable}
              >
                INVITED STORES
              </Typography>
              <Stack spacing={2}>
                {invites?.length > 0 ? (
                  invites?.map((item) => (
                    <WrapperCard key={item.id}>
                      <Box className={classes.flex}>
                        <Box
                          sx={{
                            lineHeight: '1rem',
                            fontWeight: 'bold',
                            color: '#1c1c1c',
                          }}
                        >
                          {item?.managerEmail}
                        </Box>
                        <Box className={classes.flex}>
                          <Box
                            sx={{
                              padding: '10px',
                            }}
                          >
                            {!item.isInvitationAccepted && (
                              <Chip label="PENDING" color="warning" />
                            )}
                            {item.isInvitationAccepted && (
                              <Chip label="ACCEPTED" color="primary" />
                            )}
                          </Box>
                          <Box sx={{ textAlign: 'end' }}>
                            {!item.isInvitationAccepted && (
                              <Button
                                variant="contained"
                                size="small"
                                className={classes.cancel}
                                onClick={() =>
                                  handleAction('reSend', item?.managerEmail)
                                }
                              >
                                Resend
                              </Button>
                            )}
                          </Box>
                          <Box sx={{ textAlign: 'end' }}>
                            {!item.isInvitationAccepted && (
                              <Button
                                variant="contained"
                                size="small"
                                className={classes.cancel}
                                onClick={() => handleAction('delete', item?.id)}
                              >
                                Delete
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </WrapperCard>
                  ))
                ) : (
                  <Box
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      width: '100%',
                    }}
                  >
                    <Chip
                      label="No data found"
                      color="primary"
                      variant="outlined"
                    />
                  </Box>
                )}
              </Stack>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </MainLayout>
  );
}

export default Store;

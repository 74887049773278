import { yupResolver } from '@hookform/resolvers/yup';
import { Card, makeStyles, TextField } from '@material-ui/core';
import Box from '@mui/material/Box';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Lable from '../../components/Typography/lable';
import MainLayout from '../../layout/mainLayout';
import {
  businessSchema,
  initialState,
  inputFormArray,
} from '../auth/validation';
import { UserContext } from '../../context/UserContext';
const useStyles = makeStyles((theme) => ({
  card: {
    padding: '20px',
  },
}));

export default function MyBrandPage() {
  const {
    user: { user },
  } = useContext(UserContext);

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialState,
      businessName: user?.businessName,
      businessOwnerFirstName: user?.firstName,
      businessOwnerLastName: user?.lastName,
      businessOwnerEmail: user?.email,
      referralCode: user?.referralCode,
      salesPersonId: user?.salesPersonId,
    },
    resolver: yupResolver(businessSchema),
    mode: 'onBlur',
  });

  const classes = useStyles();

  return (
    <MainLayout>
      <Card className={classes.card}>
        <Box component="form" autoComplete="off" sx={{ mb: 2, width: '100%' }}>
          {inputFormArray.map((input) => {
            return (
              <>
                <Lable
                  style={{ my: 1, display: 'block' }}
                  title={input.lable}
                  key={input.lable}
                />
                <Controller
                  name={input.name}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      placeholder={input.placeholder}
                      name={input.name}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={errors[input.name] ? true : false}
                      helperText={errors[input.name]?.message}
                      disabled={true}
                    />
                  )}
                />
              </>
            );
          })}
        </Box>
      </Card>
    </MainLayout>
  );
}

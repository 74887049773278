/**
 * this will show after authentication is done
 * this will bring all the neccessary data of the user
 * this will decide which page to show after authentication
 */

import React, { useContext, useEffect } from 'react';
import { getStoreDetail } from '../../../service_config/admin.service';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { BUSINESSOWNER } from '../../../utilities/constants';

const AfterLoginPage = () => {
  let navigate = useNavigate();

  const {
    user: { user },
  } = useContext(UserContext);

  const storeNavigation = () => {
    getStoreDetail()
      .then((response) => {
        if (!response.error && response.result.length > 0) {
          navigate(`/home`);
        } else {
          navigate(`/create-store`);
        }
      })
      .catch((_error) => {
        navigate(`/create-store`);
      });
  };

  const handleNavigation = () => {
    if (user?.businessId) {
      if (user.role.name === BUSINESSOWNER) {
        storeNavigation();
      } else {
        navigate(`/store`);
      }
    } else {
      /**
       * refCode was set on login page
       * refCode will be handled in the next page
       */
      navigate('/info');
    }
  };

  useEffect(() => {
    handleNavigation();
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white">
      <div className="relative flex items-baseline gap-1 text-5xl font-bold">
        <style>
          {`
            @keyframes jump1 { 0%, 100% { transform: translateY(0); } 50% { transform: translateY(-35px); } }
            @keyframes jump2 { 0%, 100% { transform: translateY(0); } 50% { transform: translateY(-25px); } }
            @keyframes jump3 { 0%, 100% { transform: translateY(0); } 50% { transform: translateY(-40px); } }
            
            .jump1 { animation: jump1 1s ease-in-out infinite; }
            .jump2 { animation: jump2 1s ease-in-out infinite; }
            .jump3 { animation: jump3 1s ease-in-out infinite; }
          `}
        </style>

        <span
          className="jump2 text-baeonn-600"
          style={{ animationDelay: '0ms' }}
        >
          B
        </span>
        <span
          className="jump1 text-baeonn-500"
          style={{ animationDelay: '100ms' }}
        >
          A
        </span>
        <span
          className="jump3 text-baeonn-400"
          style={{ animationDelay: '200ms' }}
        >
          E
        </span>
        <span
          className="jump2 text-baeonn-500"
          style={{ animationDelay: '300ms' }}
        >
          O
        </span>
        <span
          className="jump1 text-baeonn-600"
          style={{ animationDelay: '400ms' }}
        >
          N
        </span>
        <span
          className="jump3 text-baeonn-700"
          style={{ animationDelay: '500ms' }}
        >
          N
        </span>

        {/* Shadow for each letter */}
        <div className="absolute -bottom-2 flex gap-1">
          <div className="h-1 w-7 animate-pulse rounded-full bg-baeonn-200"></div>
          <div
            className="h-1 w-7 animate-pulse rounded-full bg-baeonn-200"
            style={{ animationDelay: '100ms' }}
          ></div>
          <div
            className="h-1 w-7 animate-pulse rounded-full bg-baeonn-200"
            style={{ animationDelay: '200ms' }}
          ></div>
          <div
            className="h-1 w-7 animate-pulse rounded-full bg-baeonn-200"
            style={{ animationDelay: '300ms' }}
          ></div>
          <div
            className="h-1 w-7 animate-pulse rounded-full bg-baeonn-200"
            style={{ animationDelay: '400ms' }}
          ></div>
          <div
            className="h-1 w-7 animate-pulse rounded-full bg-baeonn-200"
            style={{ animationDelay: '500ms' }}
          ></div>
          <div
            className="h-1 w-7 animate-pulse rounded-full bg-baeonn-200"
            style={{ animationDelay: '600ms' }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AfterLoginPage;

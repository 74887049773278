import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import './App.css';
import Register from './views/auth/register';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../src/Theme/theme';
import component from './routes/admin.routes';
import Private from './routes/private.routes';
import TermsAndConditions from './views/TermsAncConditions';
import NearBuyStore from './views/admin/nearBuyStores';
import QRCodeGenerator from './views/auth/QRCodeGen';
import { AppProvider } from './context/AppContext';
import { UserContext, UserProvider } from './context/UserContext';
import { useContext } from 'react';
import { Toaster } from 'react-hot-toast';
import AfterLoginPage from './views/auth/register/AfterLoginPage';

function AuthRoute() {
  const { user } = useContext(UserContext);

  if (!user?.token) {
    return (
      <Routes>
        <Route path="*" element={<Navigate replace to="/login" />} />
        <Route path="/login" element={<Register />} />
        <Route path="/nearByStores" element={<NearBuyStore />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/generate-qr" element={<QRCodeGenerator />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="*" element={<Navigate replace to="/setup" />} />
      <Route path="/" element={<Navigate replace to="/setup" />} />
      <Route path="/setup" element={<AfterLoginPage />} />
      <Route path="/nearByStores" exact element={<NearBuyStore />} />
      <Route
        path="/terms-and-conditions"
        exact
        element={<TermsAndConditions />}
      />
      <Route path="/" element={<Private />}>
        {component.map((item, i) => {
          return (
            <Route key={i} path={item.path} exact element={item.element} />
          );
        })}
      </Route>
      <Route path="/generate-qr" element={<QRCodeGenerator />} />
    </Routes>
  );
}

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <UserProvider>
            <Router>
              <AuthRoute />
            </Router>
            <Toaster position="top-center" />
          </UserProvider>
        </AppProvider>
      </ThemeProvider>
    </>
  );
}

export default App;

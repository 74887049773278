export const truncateString = (str, num) =>
  str?.length > num ? str.slice(0, num > 3 ? num - 3 : num) + '...' : str;

export const splitCoupons = (noOfCoupons, mediaPartners) => {
  const result = [];
  let index = 0;
  for (let i = 0; i < noOfCoupons % mediaPartners.length; i += 1) {
    result.push({
      ...mediaPartners[index],
      noOfCoupons: Math.ceil(noOfCoupons / mediaPartners.length),
    });
    index += 1;
  }
  for (
    let i = 0;
    i < mediaPartners.length - (noOfCoupons % mediaPartners.length);
    i += 1
  ) {
    result.push({
      ...mediaPartners[index],
      noOfCoupons: Math.floor(noOfCoupons / mediaPartners.length),
    });
    index += 1;
  }
  return result;
};

export const capitalizeWords = (str) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browserName = 'Unknown';

  if (userAgent.indexOf('Firefox') > -1) {
    browserName = 'Mozilla Firefox';
  } else if (userAgent.indexOf('SamsungBrowser') > -1) {
    browserName = 'Samsung Internet';
  } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
    browserName = 'Opera';
  } else if (userAgent.indexOf('Trident') > -1) {
    browserName = 'Microsoft Internet Explorer';
  } else if (userAgent.indexOf('Edge') > -1) {
    browserName = 'Microsoft Edge';
  } else if (userAgent.indexOf('CriOS') > -1) {
    browserName = 'Google Chrome';
  } else if (userAgent.indexOf('Chrome') > -1) {
    browserName = 'Google Chrome';
  } else if (userAgent.indexOf('Safari') > -1) {
    browserName = 'Apple Safari';
  }

  return browserName;
};

export const getLocation = () =>
  new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          if (error.message === 'Timeout expired') {
            reject({ message: 'Location request timed out' });
            return;
          }
          if (
            error.message ==
            'Origin does not have permission to use Geolocation service'
          ) {
            const browser = getBrowserInfo();
            reject({
              message: `Please give ${browser} access to location service`,
            });
            return;
          } else reject(error);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    } else {
      reject('Geolocation is not supported by this browser.');
    }
  });

export const weekNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

import React, { useState, useRef } from 'react';
import { FaCheck } from 'react-icons/fa';

const BaeDragAndDropUpload = ({
  onFilesAdded,
  fileLimit,
  label,
  required = false,
  id = 'bae-drag-and-drop-upload',
  image = null,
}) => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (fileLimit && droppedFiles.length > fileLimit) {
      alert(`You can only upload up to ${fileLimit} files.`);
      return;
    }
    setFiles(droppedFiles);
    if (onFilesAdded) {
      onFilesAdded(droppedFiles);
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (fileLimit && selectedFiles.length > fileLimit) {
      alert(`You can only upload up to ${fileLimit} files.`);
      return;
    }
    setFiles(selectedFiles);
    if (onFilesAdded) {
      onFilesAdded(selectedFiles);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      className={`relative border-dashed border-2 p-4 rounded-md ${dragging ? 'border-blue-500' : 'border-gray-300'}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        type="file"
        multiple
        className="hidden"
        ref={fileInputRef}
        onChange={handleFileChange}
        id={id}
      />
      <div className="text-center">
        <p>Drag and drop files here, or click to select files</p>
        <button
          type="button"
          className="mt-2 px-4 py-2 bg-baeonn-800 text-white rounded-md"
          onClick={handleButtonClick}
        >
          {label || 'Upload Files'}
        </button>
      </div>
      {files.length > 0 && (
        <div className="mt-4">
          <h4>Files:</h4>
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}
      {!required && (
        <FaCheck className="absolute bottom-4 right-4 text-baeonn-500" />
      )}
      {image && (
        <a href={image} target="_blank" rel="noopener noreferrer">
          <img className="w-6 h-6 object-cover" src={image} alt="Store" />
        </a>
      )}
    </div>
  );
};

export default BaeDragAndDropUpload;

import { useEffect, useState } from 'react';
import { capitalizeWords } from '../../../utilities/commonFunction';
import { ImageTag, ViewAndEditButton, QRCodeTag } from './components';
import { get } from '../../../service_config/http.service';

const StoreCard = ({ store }) => {
  const [qr, setQr] = useState(null);

  useEffect(() => {
    get(`/stores/qr-store-mapped?store_id=${store.id}`)
      .then((response) => {
        if (response.status === 200 && response?.data?.result?.qrData) {
          setQr(response.data.result.qrData);
        }
      })
      .catch((error) => {
        //  Handle error
      });
  }, []);

  return (
    <div className="bg-gray-100 rounded-lg shadow-md overflow-hidden border border-gray-300">
      <div className="flex flex-col md:flex-row">
        {' '}
        {/* Change flex direction for responsiveness */}
        <ImageTag image={store?.logoUrl} />
        <div className="p-6 w-full md:w-2/3">
          <h2 className="text-2xl font-bold text-gray-900 text-center md:text-left">
            {capitalizeWords(store?.branchName || '')}
          </h2>{' '}
          {/* Center on mobile, left-align on larger screens */}
          <p className="mt-2 text-gray-600 text-center md:text-left">
            {store?.email}
          </p>{' '}
          {/* Center on mobile, left-align on larger screens */}
          <div className="mt-4 flex justify-center md:justify-start space-x-4">
            {' '}
            {/* Center buttons on mobile, left-align on larger screens */}
            <ViewAndEditButton store={store} />
          </div>
        </div>
        <QRCodeTag qrCode={qr} storeId={store.id} />
      </div>
    </div>
  );
};

export default StoreCard;

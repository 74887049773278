const CAMPAIGN_TYPE = {
  DEAL_POOL: 'Deal Pool',
  TARGETED: 'Targeted',
};

const COUPON_TYPE = {
  GENERATE: 'Generate',
  PREDEFINED: 'Predefined',
};

const firstLetterCapital = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const checkRequiredFields = (REQUIRED_FIELDS, data) => {
  let isValid = true;

  const validateFields = (fields, data) => {
    for (const key in fields) {
      if (typeof fields[key] === 'boolean') {
        if (fields[key] && !data[key]) {
          isValid = false;
          break;
        }
      } else if (fields[key].type === 'array' && fields[key].required) {
        if (!Array.isArray(data[key]) || data[key].length === 0) {
          isValid = false;
          break;
        }
      } else if (typeof fields[key] === 'object') {
        validateFields(fields[key], data[key]);
      }
    }
  };

  validateFields(REQUIRED_FIELDS, data);

  return isValid;
};

const URL_REGEX = /^(https?:\/\/)?(www\.)?[^\s]+?\.[a-zA-Z]{2,63}(\/[^\s]*)?$/;

export {
  CAMPAIGN_TYPE,
  COUPON_TYPE,
  firstLetterCapital,
  checkRequiredFields,
  URL_REGEX,
};

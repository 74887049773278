import { useContext } from 'react';
import BaeonnQR from '../../auth/QRCodeGen/BaeonnQr';
import { UserContext } from '../../../context/UserContext';
const { Link } = require('react-router-dom');
const { getCurrentUser } = require('../../../service_config/auth.service');
const { STOREMANAGER } = require('../../../utilities/constants');

const ImageTag = ({ image }) => {
  return (
    <div className="relative w-full h-32 md:w-52 md:h-auto">
      {' '}
      {/* Adjust height for mobile */}
      {image ? (
        <img className="w-full h-full object-cover" src={image} alt="Store" />
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full bg-gray-200">
          <p className="mb-2 text-gray-700">Upload Image</p>
          <i className="fa fa-upload text-gray-700 text-2xl"></i>
        </div>
      )}
    </div>
  );
};

const QRCodeTag = ({ qrCode, storeId }) => {
  // eslint-disable-next-line prettier/prettier
  const handlePrintClick = () => { };
  return (
    <div className="relative w-full h-32 md:w-1/3 md:h-auto group">
      {' '}
      {/* Add group class for hover effect */}
      {qrCode ? (
        <div className="relative w-full h-full">
          <div className="absolute inset-0 w-full h-full flex items-center justify-center overflow-hidden">
            <BaeonnQR qr={qrCode} className="w-full h-full object-contain" />{' '}
            {/* Set QR code as background */}
          </div>
          <div className="relative flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-50">
            <Link
              to={`/print-store-qr?code=${encodeURIComponent(qrCode)}`}
              target="_blank"
            >
              <button
                className="mb-2 text-white md:bg-opacity-10 bg-primary bg-opacity-75 px-2 py-1 rounded transition duration-300 group-hover:bg-opacity-90"
                onClick={handlePrintClick}
              >
                Show | Print
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <Link
          to={`/attach-qr?store_id=${encodeURIComponent(storeId)}`}
          target="_blank"
        >
          <button className="flex flex-col items-center justify-center w-full h-full bg-gray-200 hover:bg-gray-300 focus:outline-none">
            <p className="mb-2 text-gray-700">Attach QR Code</p>
            <i className="fa fa-qrcode text-gray-700 text-2xl"></i>
          </button>
        </Link>
      )}
    </div>
  );
};

const ViewAndEditButton = ({ store }) => {
  const {
    user: { user },
  } = useContext(UserContext);
  return (
    <Link
      to={`/store/${store.id}`}
      state={{
        id: store.id,
        isEdit:
          user.role.name === STOREMANAGER && store.branchName === ''
            ? true
            : false,
      }}
    >
      <button className="bg-primary text-white px-4 py-2 rounded">
        View | Edit
      </button>
    </Link>
  );
};

export { ImageTag, QRCodeTag, ViewAndEditButton };

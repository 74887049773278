import React, { useState, useRef, useEffect } from 'react';

const BaeDropdown = ({
  rows,
  field,
  callback,
  className,
  selectText = 'Select',
  value,
}) => {
  const [selected, setSelected] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSelect = (row) => {
    setSelected(row[field]);
    setIsOpen(false);
    if (callback) {
      callback(row);
    }
  };

  useEffect(() => {
    if (!value || !rows || !field) return;
    const selectedRow = rows.find((row) => row[field] === value);
    if (selectedRow) {
      setSelected(selectedRow[field]);
    }
  }, [rows, field, value]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative w-full ${className}`} ref={dropdownRef}>
      <div className="z-10">
        <button
          type="button"
          className="rounded-md inline-flex justify-between w-full border border-gray-300 shadow-md px-2 py-4 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          onClick={toggleDropdown}
        >
          {selected || selectText}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="absolute z-20 border rounded-md origin-top-right left-0 mt-2 w-full shadow-md bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {rows &&
              rows.length > 0 &&
              rows.map((row, index) => (
                <button
                  key={index}
                  className="block px-4 py-4 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  role="menuitem"
                  onClick={() => handleSelect(row)}
                >
                  {row[field]}
                </button>
              ))}

            {/* placeholder */}
            {!rows && (
              <button
                key={1}
                className="block px-4 py-4 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                role="menuitem"
              >
                This Emptiness
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BaeDropdown;

import { useEffect, useState } from 'react';
import {
  BaeButton,
  BaeHeading,
  BaeSmallText,
  BaeTextInput,
} from '../../../components/Bae';
import BaeDragAndDropUpload from '../../../components/Bae/BaeDragAndDropUpload';
import FileUploadHoc from '../../../components/File.Upload';
import {
  getBankDetail,
  submitBankDetail,
} from '../../../service_config/admin.service';
import toast from 'react-hot-toast';
import * as yup from 'yup';

const bankDetailValidationSchema = yup.object().shape({
  account_number: yup.string().required('Account number is required'),
  pan_number: yup.string().required('Pan number is required'),
  account_holder_name: yup.string().required('Account holder name is required'),
  bank_name: yup.string().required('Bank name is required'),
  ifsc_code: yup.string().required('IFSC code is required'),
  kyc_document: yup.string().required('KYC document is required'),
});

const BankDetails = () => {
  const [bankDetails, setBankDetails] = useState({});
  const [disabled, setDisabled] = useState(true);

  const handleFilesAdded = (files, type) => {
    if (type === 'bankStatement') {
      setBankDetails((prev) => ({
        ...prev,
        kyc_document: files,
      }));
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    submitBankDetail(bankDetails)
      .then((res) => {
        toast.success(res.result.msg);
      })
      .catch((_) => {
        toast.error(_.result.msg);
      });
  };

  useEffect(() => {
    bankDetailValidationSchema
      .validate(bankDetails, { abortEarly: false })
      .then(() => {
        setDisabled(false);
      })
      .catch(() => {
        setDisabled(true);
      });
  }, [bankDetails]);

  useEffect(() => {
    getBankDetail()
      .then((res) => {
        setBankDetails(res.result.bank_detail);
      })
      .catch((_) => {
        toast.error(_.result.msg);
      });
  }, []);

  return (
    <div className="">
      <div className="mt-4">
        <BaeHeading>Bank</BaeHeading>
      </div>

      <div className="mt-4 mb-4 grid md:grid-cols-2 grid-cols-1 gap-4">
        <div>
          <BaeSmallText className={'block mb-1'}>Account Number</BaeSmallText>
          <BaeTextInput
            value={bankDetails?.account_number}
            callback={(v) => {
              setBankDetails({
                ...bankDetails,
                account_number: v,
              });
            }}
          />
        </div>
        <div>
          <BaeSmallText className={'block mb-1'}>Pan Number</BaeSmallText>
          <BaeTextInput
            value={bankDetails?.pan_number}
            callback={(v) => {
              setBankDetails({
                ...bankDetails,
                pan_number: v,
              });
            }}
          />
        </div>
        <div className="md:col-span-2">
          <BaeSmallText className={'block mb-1'}>
            Account holder name
          </BaeSmallText>
          <BaeTextInput
            value={bankDetails?.account_holder_name}
            callback={(v) => {
              setBankDetails({
                ...bankDetails,
                account_holder_name: v,
              });
            }}
          />
        </div>
        <div>
          <BaeSmallText className={'block mb-1'}>Bank name</BaeSmallText>
          <BaeTextInput
            value={bankDetails?.bank_name}
            callback={(v) => {
              setBankDetails({
                ...bankDetails,
                bank_name: v,
              });
            }}
          />
        </div>
        <div>
          <BaeSmallText className={'block mb-1'}>IFSC code</BaeSmallText>
          <BaeTextInput
            value={bankDetails?.ifsc_code}
            callback={(v) => {
              setBankDetails({
                ...bankDetails,
                ifsc_code: v,
              });
            }}
          />
        </div>
      </div>
      <div>
        <BaeSmallText className={'block mb-1'}>
          Cancelled cheque / Bank Statement with name
        </BaeSmallText>
        <FileUploadHoc
          fileType={'bankStatement'}
          set={(l, t) => handleFilesAdded(l, t)}
        >
          <BaeDragAndDropUpload
            label={'Upload bank proof'}
            fileLimit={1}
            id="bankStatement"
            image={bankDetails?.kyc_document}
          />
        </FileUploadHoc>
      </div>

      <BaeButton
        className={'mt-4'}
        onClick={onSubmitHandler}
        disable={disabled}
      >
        Save
      </BaeButton>
    </div>
  );
};

export default BankDetails;

import { useState } from 'react';
import BaeonnLogo from '../../../assets/bae-logo.png';
import { BaeButton } from '../../../components/Bae';

const NumberInputForm = ({ onSubmit, reEnter, reSend }) => {
  const [otp, setOtp] = useState('');

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(otp);
  };

  return (
    <div className="bg-white md:shadow-lg rounded-lg p-8 md:p-8 w-full max-w-md ml-auto mr-0 md:mr-10">
      {' '}
      {/* Centered form with max width */}
      <img
        src={BaeonnLogo}
        alt="Baeonn Logo"
        className="w-full h-28 object-cover cursor-pointer mb-4"
      />
      <h2 className="text-2xl font-bold mb-4">Enter OTP</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <div className="flex">
            <input
              type="number"
              value={otp}
              onChange={handleChange}
              className="w-full p-3 border rounded-r"
              placeholder="Enter your  OTP"
            />
          </div>
        </div>
        <BaeButton className="mb-2">Submit OTP</BaeButton>
      </form>
      <BaeButton className="mb-2" onClick={reSend}>
        {' '}
        Re-send OTP
      </BaeButton>
      <BaeButton className="mb-2" onClick={reEnter}>
        {' '}
        Re-enter number
      </BaeButton>
      <p className="mt-4 text-gray-600 text-sm">
        By Signing up you accept our{' '}
        <a href="/terms" className="text-primary">
          Terms & Conditions
        </a>
      </p>
      <p className="mt-4 text-gray-600 text-sm">
        <a href="/stores" className="text-primary">
          View stores nearby
        </a>
      </p>
    </div>
  );
};

export default NumberInputForm;

import { useContext, useEffect, useState } from 'react';
import logo from '../../../assets/Partnership-bro.svg';
import NumberInputForm from './NumberInputForm';
import OtpInputForm from './OtpInputForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { genrateOtp, verifyOtp } from '../../../service_config/auth.service';
import toast from 'react-hot-toast';
import { UserContext } from '../../../context/UserContext';
import { AppContext } from '../../../context/AppContext';

const Login = () => {
  let navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [phone, setPhone] = useState('');

  const [searchParams] = useSearchParams();
  const invitationToken = searchParams.get('token');
  const referredUserId = searchParams.get('refUser');
  const referralCode = searchParams.get('refCode');

  const { updateUser } = useContext(UserContext);
  const { updateData } = useContext(AppContext);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the state based on the initial window size

    if (referredUserId && referralCode) {
      updateData({ referredUserId, referralCode });
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const _makeOtpRequest = (number) => {
    return genrateOtp({
      mobileNumber: number,
      ...(invitationToken && { invitationToken }),
    });
  };

  const _makeOtpVerificationRequest = (otp) => {
    return verifyOtp({ mobileNumber: phone, otp });
  };

  const onNumberSubmit = (number) => {
    if (!number || number.length < 10) {
      toast.error('Please enter a valid phone number');
      return;
    }
    setPhone(number);
    _makeOtpRequest(number)
      .then((response) => {
        if (response.error) {
          toast.error('Somethig went wrong, try again');
          return;
        }
        setShowOtpForm(true);
        toast.success(`OTP sent successfully to: ${number}`);
      })
      .catch((_error) => {
        setShowOtpForm(false);
        toast.error('Somethig went wrong, try again', 'error');
      });
  };

  const onOtpSubmit = (otp) => {
    if (!otp || otp.length <= 0) {
      toast.error('Please enter a valid OTP');
      return;
    }
    _makeOtpVerificationRequest(otp)
      .then((response) => {
        if (response.error) {
          toast.error(response.msg);
          return;
        }
        if (response?.result) {
          updateUser({
            user: response.result,
            token: response.result.authToken,
          });
        }
        // handleNavigation(response);
        toast.success(`OTP verified successfully`);
      })
      .catch((_error) => {
        toast.error('Somethig went wrong, try again');
      });
  };

  const onReEnter = () => {
    setShowOtpForm(false);
  };

  const onReSend = () => {
    onNumberSubmit(phone);
  };

  const handleGenerateQR = () => {
    const qrValue = 'Baeonn';
    navigate(`/generate-qr?qrValue=${qrValue}`);
  };

  return (
    <div
      className={`flex items-center justify-center min-h-screen ${isMobile ? 'bg-none' : 'md:bg-cover md:bg-center'}`}
      style={{ backgroundImage: isMobile ? 'none' : `url(${logo})` }}
    >
      {!showOtpForm ? (
        <NumberInputForm
          onSubmit={onNumberSubmit}
          generateQr={handleGenerateQR}
        />
      ) : (
        <OtpInputForm
          onSubmit={onOtpSubmit}
          reEnter={onReEnter}
          reSend={onReSend}
        />
      )}
    </div>
  );
};

export default Login;

import { useEffect, useState } from 'react';
import {
  BaeButton,
  BaeErrorText,
  BaeHeading,
  BaeHorizontalLine,
  BaeSmallText,
  BaeSmallTextButton,
  BaeTextInput,
  BaeWarningText,
} from '../../../../components/Bae';
import MainLayout from '../../../../layout/mainLayout';
import {
  getCampaignByIdV2,
  updateCampaignById,
} from '../../../../service_config/admin.service';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const PaymentDetails = ({ payment }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      <div>
        <BaeSmallText>Amount</BaeSmallText>
        <p>{payment?.amount}</p>
      </div>
      <div>
        <BaeSmallText>Baeonn Commission</BaeSmallText>
        <p>{payment?.baeonn_commission}</p>
      </div>
      <div>
        <BaeSmallText>Credits used</BaeSmallText>
        <p>{payment?.credit_used}</p>
      </div>
      <div>
        <BaeSmallText>Total</BaeSmallText>
        <p>{payment?.grand_total}</p>
      </div>
      <div className="col-span-2 md:col-span-1">
        <BaeSmallText>Payment Date</BaeSmallText>
        <p>{payment?.created_on}</p>
      </div>
      <div className="col-span-2 md:col-span-1">
        <BaeSmallText>Transaction ID</BaeSmallText>
        <p className="text-wrap">{payment?.transaction_id}</p>
      </div>
    </div>
  );
};

const Actions = ({ actions, handleUpdate, handlePayClick }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {actions?.activate_button && (
        <BaeButton
          className="bg-baeonn-700 active:bg-baeonn-400"
          onClick={(e) => {
            e.preventDefault();
            handlePayClick();
          }}
        >
          Activate
        </BaeButton>
      )}

      {actions?.pause_button && (
        <BaeButton
          className="bg-orange-400 active:bg-orange-300"
          onClick={(e) => {
            e.preventDefault();
            handleUpdate({ isActive: false });
          }}
        >
          Pause
        </BaeButton>
      )}

      {actions?.resume_button && (
        <BaeButton
          className="bg-baeonn-700 active:bg-baeonn-500"
          onClick={(e) => {
            e.preventDefault();
            handleUpdate({ isActive: true });
          }}
        >
          Resume
        </BaeButton>
      )}

      {actions?.stop_button && (
        <BaeButton
          className="bg-red-700 active:bg-red-400"
          onClick={(e) => {
            e.preventDefault();
            handleUpdate({ isActive: false });
          }}
        >
          Stop
        </BaeButton>
      )}
    </div>
  );
};

const CampaignDetails = ({ campaign, getData }) => {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState({
    title: campaign?.title,
    description: campaign?.description,
  });

  const handleUpdate = (e) => {
    e.preventDefault();
    updateCampaignById(campaign.id, value)
      .then((_) => {
        if (_.result.msg) {
          toast.success(_.result.msg);
        }
        getData();
        setIsEdit((e) => !e);
      })
      .catch((_) => {
        if (_.msg) {
          toast.success(_.msg);
        }
      });
  };

  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <BaeSmallText>Title</BaeSmallText>
          {!isEdit ? (
            <div>
              <p className="inline">{campaign?.title}</p>{' '}
              <BaeSmallTextButton onClick={() => setIsEdit((edit) => !edit)}>
                Edit
              </BaeSmallTextButton>
            </div>
          ) : (
            <div>
              <BaeTextInput
                value={value?.title}
                required={true}
                callback={(v) => setValue({ ...value, title: v })}
              />
            </div>
          )}
        </div>
        <div>
          <BaeSmallText>Campaign Type</BaeSmallText>
          <p>{campaign?.campaign_type}</p>
        </div>
        <div>
          <BaeSmallText>Description</BaeSmallText>

          {!isEdit ? (
            <div>
              <p className="inline">{campaign?.description}</p>{' '}
              <BaeSmallTextButton onClick={() => setIsEdit((edit) => !edit)}>
                Edit
              </BaeSmallTextButton>
            </div>
          ) : (
            <div>
              <BaeTextInput
                value={value?.description}
                required={true}
                callback={(v) => setValue({ ...value, description: v })}
              />
            </div>
          )}
        </div>
        <div>
          <BaeSmallText>Coupons claimed/shared/total</BaeSmallText>
          <p>
            {campaign?.total_coupons_claimed} / {campaign?.total_coupons_shared}{' '}
            / {campaign?.total_coupons}
          </p>
        </div>
        <div>
          <BaeSmallText>Start Date</BaeSmallText>
          <p>{campaign?.start_date}</p>
        </div>
        <div>
          <BaeSmallText>End Date</BaeSmallText>
          <p>{campaign?.end_date}</p>
        </div>
        <div>
          <BaeSmallText>Expiry Date</BaeSmallText>
          <p>{campaign?.expiry_date}</p>
        </div>
        <div>
          <BaeSmallText>Creation Date</BaeSmallText>
          <p>{campaign?.created_on}</p>
        </div>
        <div>
          <BaeSmallText>Status</BaeSmallText>
          <p>{campaign?.is_active}</p>
        </div>
        <div>
          <BaeSmallText>Running</BaeSmallText>
          <p>{campaign?.is_running}</p>
        </div>
        <div>
          <BaeSmallText>Store</BaeSmallText>
          <p
            className="cursor-pointer text-blue-500 hover:underline"
            onClick={() => {
              navigate(`/store/${campaign?.store_id}`);
            }}
          >
            {campaign?.store_name}
          </p>
        </div>
        <div>
          <BaeSmallText> Pincode</BaeSmallText>
          <p>{campaign?.store_pincode}</p>
        </div>
      </div>

      {isEdit && (
        <div className="grid grid-cols-12 gap-1 mt-2">
          <BaeButton
            className="bg-baeonn-red-600 col-span-4"
            onClick={() => setIsEdit((e) => !e)}
          >
            Cancel
          </BaeButton>
          <BaeButton className={'col-span-8'} onClick={handleUpdate}>
            Update
          </BaeButton>
        </div>
      )}
    </div>
  );
};

const CampaignDetail = () => {
  const navigate = useNavigate();
  const { campaign_id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [actions, setActions] = useState({});
  const [payment, setPayment] = useState({});

  const getData = () => {
    getCampaignByIdV2(campaign_id)
      .then((res) => {
        setCampaign(res.result.campaign);
        setActions(res.result.actions);
        setPayment(res.result.fee);
      })
      .catch((_) => {
        // eslint-disable-next-line no-console
        console.log(_);
      });
  };

  useEffect(() => {
    getData();
  }, [campaign_id]);

  const handleUpdate = (data) => {
    updateCampaignById(campaign_id, data)
      .then((_) => {
        if (_.result.msg) {
          toast.success(_.result.msg);
        }
        getData();
      })
      .catch((_) => {
        if (_.msg) {
          toast.success(_.msg);
        }
      });
  };

  const handlePayClick = () => {
    navigate(`/campaign-activation/${campaign_id}`);
  };

  return (
    <MainLayout>
      <div>
        <div className="mb-4">
          <BaeHeading>Campaign Details</BaeHeading>
        </div>

        <div className="mb-4">
          <BaeWarningText>
            <BaeSmallText>
              Status should be in Active state else campaign will not start
            </BaeSmallText>
          </BaeWarningText>
        </div>

        {campaign && <CampaignDetails campaign={campaign} getData={getData} />}

        <BaeHorizontalLine />

        <div className="mb-4">
          <BaeHeading>Payment Details</BaeHeading>
        </div>

        {payment && <PaymentDetails payment={payment} />}
        {!payment && (
          <BaeErrorText>
            <BaeSmallText>No payment details available.</BaeSmallText>
          </BaeErrorText>
        )}

        <BaeHorizontalLine />

        <div className="mb-4">
          <BaeHeading>Actions</BaeHeading>
        </div>

        {actions && !actions.no_actions && (
          <Actions
            actions={actions}
            handleUpdate={handleUpdate}
            handlePayClick={handlePayClick}
          />
        )}
        {actions.no_actions && (
          <BaeErrorText>
            <BaeSmallText>No actions available.</BaeSmallText>
          </BaeErrorText>
        )}
      </div>
    </MainLayout>
  );
};

export default CampaignDetail;

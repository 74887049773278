import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Html5Qrcode } from 'html5-qrcode';
import toast from 'react-hot-toast';

const AttachQr = () => {
  const [isCameraActive, setIsCameraActive] = useState(true);
  const [result, setResult] = useState(null);
  const lastErrorTimeRef = useRef(null);
  const [closing, setClosing] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const store_id = queryParams.get('store_id');

  const closeWindow = () => {
    setClosing(true);
    setTimeout(() => {
      window.close();
    }, 5000);
  };

  const makeStoreQrMappingRequet = async (qr_data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}stores/qr-store-mapping`,
        {
          store_id: store_id,
          qr_uuid: qr_data,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200 && response.data.error === false) {
        toast.success('QR Code successfully mapped!');
        setResult({ error: false, msg: 'QR Code successfully mapped!' });
      } else {
        toast.error('Failed to map QR Code.');
        setResult({
          error: true,
          msg: response?.data?.result || 'Failed to map QR Code.',
        });
      }
      closeWindow();
    } catch (error) {
      toast.error('An error occurred while mapping the QR Code.');
      closeWindow();
    }
  };

  const handleSuccess = (data) => {
    toast.success('Scanned succesfully');
    setIsCameraActive(false); // Close the camera
    makeStoreQrMappingRequet(data);
  };

  const handleError = (_error) => {
    const now = new Date().getTime();
    const interval = 10000; // 10 seconds in milliseconds
    if (
      !lastErrorTimeRef.current ||
      now - lastErrorTimeRef.current > interval
    ) {
      lastErrorTimeRef.current = now;
      toast.error('Please scan a QR');
    }
  };

  const qrCodeSuccessCallback = (decodedText, _decodedResult) => {
    // eslint-disable-next-line no-console
    console.log('decodedText', { decodedText, _decodedResult });
    handleSuccess(decodedText);
  };

  const qrCodeErrorCallback = (errorMessage) => {
    // eslint-disable-next-line no-console
    console.log('errorMessage ', errorMessage);
    handleError(errorMessage);
  };

  const startScanner = async () => {
    const html5Qrcode = new Html5Qrcode('qr-reader');

    try {
      // Get available cameras
      const cameras = await Html5Qrcode.getCameras();
      if (cameras && cameras.length > 0) {
        // Find the back camera (usually labeled as "environment" or "rear")
        //try to fing the back camera else use the first camera
        let backCamera = cameras.find(
          (camera) =>
            camera.label.toLowerCase().includes('back') ||
            camera.label.toLowerCase().includes('rear')
        );

        if (!backCamera) {
          backCamera = cameras[0];
        }

        if (backCamera) {
          // Start the scanner with the back camera
          await html5Qrcode.start(
            backCamera.id,
            { fps: 5, qrbox: 200 },
            qrCodeSuccessCallback,
            qrCodeErrorCallback
          );
        } else {
          alert('Back camera not found');
        }
      } else {
        alert('No cameras found');
      }
    } catch (error) {
      alert('Error starting scanner:', error);
    }
  };

  useEffect(() => {
    startScanner();
    // const scanner = new Html5QrcodeScanner(
    //   'qr-reader',
    //   { fps: 10, qrbox: 250 },
    //   /* verbose= */ false
    // );

    // scanner.render(
    //   (decodedText, _decodedResult) => {
    //     handleSuccess(decodedText);
    //   },
    //   (errorMessage) => {
    //     handleError(errorMessage);
    //   }
    // );
  }, []);

  return (
    <>
      <div className="w-screen h-screen flex items-center justify-center">
        <div id="qr-reader" className="w-full h-full relative">
          <div className="absolute inset-0">
            {/* Scanner will be initialized here */}
          </div>
        </div>
      </div>
      {/* <div id="qr-reader"></div> */}
      {/* <p className="text-center text-lg font-semibold text-gray-800 p-4 bg-white rounded shadow-md">
        {result
          ? result.msg
          : 'Please scan a QR code to attach it to the store.'}
      </p>
      {closing && (
        <p className="text-center text-lg font-semibold text-gray-800 p-4 bg-white rounded shadow-md">
          {`Windows will be auto closed in 5 seconds.`}
        </p>
      )} */}
    </>
  );

  //   <>
  //     {isCameraActive && (
  //       <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
  //         <div className="w-full h-full">
  //           <QrReader
  //             onResult={(result, error) => {
  //               if (result) {
  //                 handleSuccess(result?.text);
  //               }

  //               if (error) {
  //                 handleError(error);
  //               }
  //             }}
  //             style={{ width: '100%', height: '100%' }}
  //           />
  //         </div>
  //       </div>
  //     )}
  //     <p className="text-center text-lg font-semibold text-gray-800 p-4 bg-white rounded shadow-md">
  //       {result
  //         ? capitalizeWords(result.msg)
  //         : 'Please scan a QR code to attach it to the store.'}
  //     </p>
  //     {closing && (
  //       <p className="text-center text-lg font-semibold text-gray-800 p-4 bg-white rounded shadow-md">
  //         {`Windows will be auto closed in 5 seconds.`}
  //       </p>
  //     )}
  //   </>
  // );
};

export default AttachQr;

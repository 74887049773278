import React, { createContext, useState, useEffect } from 'react';

const BaseContextConfig = {
  user: {},
  token: '',
};

// Create a context
const UserContext = createContext();

// Create a provider component
const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Get initial state from localStorage if available
    const savedData = localStorage.getItem('UserData');
    return savedData ? JSON.parse(savedData) : BaseContextConfig;
  });

  useEffect(() => {
    // Save data to localStorage whenever it changes
    localStorage.setItem('UserData', JSON.stringify(user));
  }, [user]);

  const updateUser = (newData) => {
    setUser((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const deleteUser = () => {
    setUser(BaseContextConfig);
    localStorage.removeItem('UserData');
  };

  return (
    <UserContext.Provider value={{ user, updateUser, deleteUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };

/* eslint-disable prettier/prettier */
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useContext, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Image from '../../assets/bae-logo.png';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';

import Link from '@mui/material/Link';
import { AiOutlinePoweroff, AiTwotoneHome } from 'react-icons/ai';
import { HiPlusCircle } from 'react-icons/hi';
import {
  MdBusinessCenter,
  MdCampaign,
  MdGroupAdd,
  MdStore,
  MdWallet,
} from 'react-icons/md';
import { RiCouponFill, RiShareForwardFill } from 'react-icons/ri';
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { getStoreDetail } from '../../service_config/admin.service';
import { BUSINESSOWNER, STOREMANAGER } from '../../utilities/constants';
import { AppContext } from '../../context/AppContext';
import { UserContext } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: '3%',
    cursor: 'pointer',
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',
    color: '#57748a',
  },

  btn: {
    background: 'white',
    color: 'blue',
    fontWeight: '700',
    '&:hover': {
      background: 'blue',
      color: 'white',
    },
  },
  inputfile: {
    display: 'none',
  },

  icon: {
    transform: 'rotate(180deg)',
  },
  grid: {
    padding: '25px 0',
  },
  profile: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
  },

  isactive: {
    color: 'red',
    background: 'green',
  },
  iconPosistion: {
    posistion: 'relative',
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const stores = useSelector((state) => state.storeDetail.stores);
  const navigate = useNavigate();
  const { data: { disableSidebarMenuButtons: disableButtons }, updateData } = useContext(AppContext);
  const { user: { user }, deleteUser } = useContext(UserContext);

  useEffect(() => {
    getStoreDetail();
  }, []);

  useEffect(() => {
    if (stores?.length === 0) {
      updateData({ disableSidebarMenuButtons: true });
    } else {
      updateData({ disableSidebarMenuButtons: false });
    }
  }, [stores]);

  const logOut = () => {
    deleteUser();
  };

  const storeMangerAccess = [
    'SHARE COUPONS',
    'VIEW CAMPAIGNS',
    'STORES',
    'REFER & EARN',
    'OUR CLIENTS',
    'CLAIM COUPON',
  ];

  const location = useLocation();
  const menuOne = [
    {
      name: 'SHARE COUPONS',
      path: '/shareCoupons',
      active: '/shareCoupons',
      icon: (
        <div className={classes.iconPosistion}>
          <RiCouponFill size={20} />
          <RiShareForwardFill
            size={13}
            style={{ position: 'absolute', top: '5', left: '33' }}
          />
        </div>
      ),
      activeIcon: (
        <div className={classes.iconPosistion}>
          <RiCouponFill size={20} style={{ color: '#33c8f5' }} />
          <RiShareForwardFill
            size={13}
            style={{
              position: 'absolute',
              top: '5',
              left: '33',
              color: '#33c8f5',
            }}
          />
        </div>
      ),
    },
  ];
  const menuTwo = [
    {
      name: 'CLAIM COUPON',
      path: '/claimCoupons',
      active: '/claimCoupons',
      icon: <RiCouponFill size={20} />,
      activeIcon: <RiCouponFill style={{ color: '#33c8f5' }} size={20} />,
    },
    {
      name: 'VIEW CAMPAIGNS',
      path: '/viewCampaigns',
      active: '/viewCampaigns',
      icon: <MdCampaign size={20} />,
      activeIcon: <MdCampaign style={{ color: '#33c8f5' }} size={20} />,
    },
    {
      name: 'CREATE A NEW CAMPAIGN',
      path: '/createCampaign',
      active: '/createCampaign',
      icon: (
        <div className={classes.iconPosistion}>
          <MdCampaign size={20} />
          <HiPlusCircle
            size={13}
            style={{ position: 'absolute', top: '5', left: '33' }}
          />
        </div>
      ),
      activeIcon: (
        <div className={classes.iconPosistion}>
          <MdCampaign size={20} style={{ color: '#33c8f5' }} />
          <HiPlusCircle
            size={13}
            style={{
              position: 'absolute',
              top: '5',
              left: '33',
              color: '#33c8f5',
            }}
          />
        </div>
      ),
    },
  ];
  const menuThree = [
    {
      name: 'HOME',
      path: '/home',
      active: '/home',
      icon: <AiTwotoneHome size={20} />,
      activeIcon: <AiTwotoneHome style={{ color: '#33c8f5' }} size={20} />,
    },
    {
      name: 'CREATE STORE',
      path: '/create-store',
      active: `/create-store`,
      icon: (
        <div className={classes.iconPosistion}>
          <MdStore size={20} />
          <HiPlusCircle
            size={13}
            style={{ position: 'absolute', top: '5', left: '33' }}
          />
        </div>
      ),
      activeIcon: (
        <div className={classes.iconPosistion}>
          <MdStore size={20} style={{ color: '#33c8f5' }} />
          <HiPlusCircle
            size={13}
            style={{
              position: 'absolute',
              top: '5',
              left: '33',
              color: '#33c8f5',
            }}
          />
        </div>
      ),
    },

    {
      name: 'STORES',
      path: '/store',
      active: '/store',
      icon: <MdStore size={20} />,
      activeIcon: <MdStore style={{ color: '#33c8f5' }} size={20} />,
    },
    {
      name: 'Wallet',
      path: '/wallet/transactions',
      active: '/wallet/transactions',
      icon: <MdWallet size={20} />,
      activeIcon: <MdWallet style={{ color: '#33c8f5' }} size={20} />,
    },
    {
      name: 'BUSINESS',
      path: '/business',
      active: '/business',
      icon: <MdBusinessCenter size={20} />,
      activeIcon: <MdBusinessCenter style={{ color: '#33c8f5' }} size={20} />,
    },

    {
      name: 'MY BRAND PAGE',
      path: '/brandpage',
      active: '/brandpage',
      icon: <MdGroupAdd size={20} />,
      activeIcon: <MdGroupAdd style={{ color: '#33c8f5' }} size={20} />,
    },
    {
      name: 'REFER & EARN',
      path: '/credit',
      active: '/credit',
      icon: <MdGroupAdd size={20} />,
      activeIcon: <MdGroupAdd style={{ color: '#33c8f5' }} size={20} />,
    },
    {
      name: 'NEAR BY STORES',
      path: '/nearby',
      active: '/nearby',
      icon: <MdGroupAdd size={20} />,
      activeIcon: <MdGroupAdd style={{ color: '#33c8f5' }} size={20} />,
    },
  ];

  //visible only on mobile view
  //for desktop view check ProfilePopup.js
  const menuProfile = [
    {
      name: 'Logout',
      icon: <AiOutlinePoweroff size={20} style={{ color: 'red' }} />,
    },
  ];

  return (
    <>
      <div>
        <div>
          <img src={Image}
            className="w-full h-28 object-cover cursor-pointer"
            alt="Sidebar Image"
            onClick={() => navigate('/home')} />
        </div>
        <Divider />
        <Box sx={{ width: '100%', px: 1, py: 2, m: 0 }}>
          <Typography
            variant="overline"
            display="block"
            sx={{
              fontSize: '0.75rem',
              lineHeight: '1rem',
              fontWeight: 'bold',
              color: '#57748a',
              padding: '10px',
            }}
          >
            MEDIA PARTNER
          </Typography>
          <List>
            {menuOne.map((text, index) => {
              if (user.role.name === STOREMANAGER) {
                if (storeMangerAccess.includes(text.name)) {
                  return (
                    <div key={index}>
                      <Link
                        component={RouterLink}
                        to={`${!disableButtons ? text.path : ''}`}
                        underline="none"
                      // activeClassName={classes.isactive}
                      >
                        <ListItem
                          disabled={disableButtons}
                          button
                          key={text}
                          sx={{
                            fontSize: '0.75rem',
                            lineHeight: '1rem',
                            fontWeight: '700',
                            color: '#1c1c1c',
                            '&&.Mui-selected': {
                              backgroundColor: 'white',
                              color: '#33c8f5',
                            },
                          }}
                          selected={text.active === location.pathname}
                        >
                          <ListItemIcon sx={{ minWidth: '30px', p: 0 }}>
                            {text.active === location.pathname
                              ? text.activeIcon
                              : text.icon}
                          </ListItemIcon>

                          {text.name}
                        </ListItem>
                      </Link>
                    </div>
                  );
                }
              }
              if (user.role.name === BUSINESSOWNER) {
                return (
                  <div key={index}>
                    <Link
                      component={RouterLink}
                      to={`${!disableButtons ? text.path : '#'}`}
                      underline="none"
                    >
                      <ListItem
                        disabled={disableButtons}
                        button
                        key={text}
                        sx={{
                          fontSize: '0.75rem',
                          lineHeight: '1rem',
                          fontWeight: '700',
                          color: '#1c1c1c',
                          '&&.Mui-selected': {
                            backgroundColor: 'white',
                            color: '#33c8f5',
                          },
                        }}
                        selected={text.active === location.pathname}
                      >
                        <ListItemIcon sx={{ minWidth: '30px', p: 0 }}>
                          {text.active === location.pathname
                            ? text.activeIcon
                            : text.icon}
                        </ListItemIcon>

                        {text.name}
                      </ListItem>
                    </Link>
                  </div>
                );
              }
            })}
          </List>
        </Box>

        <Divider />
        <Box sx={{ width: '100%', px: 1, py: 2, m: 0 }}>
          <Typography
            variant="overline"
            display="block"
            sx={{
              fontSize: '0.75rem',
              lineHeight: '1rem',
              fontWeight: 'bold',
              color: '#57748a',
              padding: '10px',
            }}
          >
            ADVERTISER
          </Typography>
          <List>
            {menuTwo.map((text, index) => {
              if (user.role.name === STOREMANAGER) {
                if (storeMangerAccess.includes(text.name)) {
                  return (
                    <div key={index}>
                      <Link
                        component={RouterLink}
                        to={`${!disableButtons ? text.path : '#'}`}
                        underline="none"
                      // activeClassName={classes.isactive}
                      >
                        <ListItem
                          disabled={disableButtons}
                          button
                          key={text}
                          sx={{
                            fontSize: '0.75rem',
                            lineHeight: '1rem',
                            fontWeight: '700',
                            color: '#1c1c1c',
                            '&&.Mui-selected': {
                              backgroundColor: 'white',
                              color: '#33c8f5',
                            },
                          }}
                          selected={text.active === location.pathname}
                        >
                          <ListItemIcon sx={{ minWidth: '30px', p: 0 }}>
                            {text.active === location.pathname
                              ? text.activeIcon
                              : text.icon}
                          </ListItemIcon>

                          {text.name}
                        </ListItem>
                      </Link>
                    </div>
                  );
                }
              }
              if (user.role.name === BUSINESSOWNER) {
                return (
                  <div key={index}>
                    <Link
                      component={RouterLink}
                      to={`${!disableButtons ? text.path : '#'}`}
                      underline="none"
                    // activeClassName={classes.isactive}
                    >
                      <ListItem
                        disabled={disableButtons}
                        button
                        key={text}
                        sx={{
                          fontSize: '0.75rem',
                          lineHeight: '1rem',
                          fontWeight: '700',
                          color: '#1c1c1c',
                          '&&.Mui-selected': {
                            backgroundColor: 'white',
                            color: '#33c8f5',
                          },
                        }}
                        selected={text.active === location.pathname}
                      >
                        <ListItemIcon sx={{ minWidth: '30px', p: 0 }}>
                          {text.active === location.pathname
                            ? text.activeIcon
                            : text.icon}
                        </ListItemIcon>

                        {text.name}
                      </ListItem>
                    </Link>
                  </div>
                );
              }
            })}
          </List>
        </Box>

        <Divider />
        <Box sx={{ width: '100%', px: 1, py: 2, m: 0 }}>
          <Typography
            variant="overline"
            display="block"
            sx={{
              fontSize: '0.75rem',
              lineHeight: '1rem',
              fontWeight: 'bold',
              color: '#57748a',
              padding: '10px',
            }}
          >
            MORE...
          </Typography>
          <List>
            <List>
              {menuThree.map((text, index) => {
                if (user.role.name === STOREMANAGER) {
                  if (storeMangerAccess.includes(text.name)) {
                    return (
                      <div key={index}>
                        <Link
                          component={RouterLink}
                          to={`${!disableButtons ? text.path : '#'}`}
                          underline="none"
                        // activeClassName={classes.isactive}
                        >
                          <ListItem
                            disabled={text.path !== '/create-store' && disableButtons}
                            button
                            key={text}
                            sx={{
                              fontSize: '0.75rem',
                              lineHeight: '1rem',
                              fontWeight: '700',
                              color: '#1c1c1c',
                              '&&.Mui-selected': {
                                backgroundColor: 'white',
                                color: '#33c8f5',
                              },
                            }}
                            selected={text.active === location.pathname}
                          >
                            <ListItemIcon sx={{ minWidth: '30px', p: 0 }}>
                              {text.active === location.pathname
                                ? text.activeIcon
                                : text.icon}
                            </ListItemIcon>

                            {text.name}
                          </ListItem>
                        </Link>
                      </div>
                    );
                  }
                }
                if (user.role.name === BUSINESSOWNER) {
                  return (
                    <div key={index}>
                      <Link
                        component={RouterLink}
                        to={`${!disableButtons ? text.path : '#'}`}
                        underline="none"
                      // activeClassName={classes.isactive}
                      >
                        <ListItem
                          disabled={text.path !== '/create-store' && disableButtons}
                          button
                          key={text}
                          sx={{
                            fontSize: '0.75rem',
                            lineHeight: '1rem',
                            fontWeight: '700',
                            color: '#1c1c1c',
                            '&&.Mui-selected': {
                              backgroundColor: 'white',
                              color: '#33c8f5',
                            },
                          }}
                          selected={text.active === location.pathname}
                        >
                          <ListItemIcon sx={{ minWidth: '30px', p: 0 }}>
                            {text.active === location.pathname
                              ? text.activeIcon
                              : text.icon}
                          </ListItemIcon>

                          {text.name}
                        </ListItem>
                      </Link>
                    </div>
                  );
                }
              })}
            </List>
          </List>

          <List>
            {menuProfile.map((text, index) => {
              return (
                <Link underline="none" key={index}>
                  <ListItem
                    key={text}
                    sx={{
                      fontSize: '0.75rem',
                      lineHeight: '1rem',
                      fontWeight: '700',
                      color: '#1c1c1c',
                      '&&.Mui-selected': {
                        backgroundColor: 'white',
                        color: '#33c8f5',
                      },
                      '&:hover': {
                        backgroundColor: '#f1f1f1',
                        cursor: 'pointer',
                      },
                    }}
                    onClick={logOut}
                  >
                    <ListItemIcon sx={{ minWidth: '30px', p: 0 }}>
                      {text.icon}
                    </ListItemIcon>

                    {text.name}
                  </ListItem>
                </Link>
              );
            })}
          </List>
        </Box>
      </div>
    </>
  );
};
export default SideBar;

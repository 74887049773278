import React, { useEffect, useState } from 'react';
import { weekNames } from '../../utilities/commonFunction';
import { FaCopy } from 'react-icons/fa';

const BaeWeekCalendar = ({ callback, value = null }) => {
  const [businessHours, setBusinessHours] = useState(
    weekNames.reduce((acc, day) => {
      acc[day] = { selected: false, start: '09:00', end: '18:00' };
      return acc;
    }, {})
  );

  useEffect(() => {
    if (value) {
      setBusinessHours(value);
    }
  }, [value]);

  const copy = () => {
    let firstSelected = null;
    for (const day of weekNames) {
      if (businessHours[day].selected) {
        firstSelected = day;
        break;
      }
    }
    if (firstSelected) {
      for (const day of weekNames) {
        if (businessHours[day].selected) {
          setBusinessHours((prevHours) => ({
            ...prevHours,
            [day]: {
              ...prevHours[day],
              start: businessHours[firstSelected].start,
              end: businessHours[firstSelected].end,
            },
          }));
        }
      }
    }
  };

  const copyTime = (e) => {
    e.preventDefault();
    copy();
  };

  const selectAllWeekdays = () => {
    setBusinessHours((prevHours) =>
      weekNames.reduce((acc, day) => {
        acc[day] = { ...prevHours[day], selected: true };
        return acc;
      }, {})
    );
  };

  const toggleDaySelection = (day) => {
    setBusinessHours((prevHours) => ({
      ...prevHours,
      [day]: {
        ...prevHours[day],
        selected: !prevHours[day].selected,
      },
    }));
  };

  const handleTimeChange = (day, type, value) => {
    setBusinessHours((prevHours) => ({
      ...prevHours,
      [day]: {
        ...prevHours[day],
        [type]: value,
      },
    }));
  };

  useEffect(() => {
    callback && callback(businessHours);
  }, [businessHours]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <h5
          id="drawer-label"
          className="inline-flex items-center mb-6 text-base font-semibold text-gray-500 uppercase "
        >
          Business hours
        </h5>
      </div>
      <div className="flex items-center justify-between">
        <button
          type="button"
          className="inline-flex items-center p-1 rounded-sm mb-6 text-base font-semibold text-gray-500 uppercase hover:text-baeonn-700 focus:outline-none active:bg-baeonn-200"
          onClick={selectAllWeekdays}
        >
          <FaCopy className="mr-1" /> Select all
        </button>
        <button
          type="button"
          className="inline-flex items-center p-1 rounded-sm mb-6 text-base font-semibold text-gray-500 uppercase hover:text-baeonn-700 focus:outline-none active:bg-baeonn-200"
          onClick={copyTime}
        >
          <FaCopy className="ml-2 mr-1" /> Apply to all
        </button>
      </div>

      <form>
        {weekNames.map((day, index) => (
          <div className="mb-6" key={index}>
            <div className="flex items-center justify-between">
              <div className="flex items-center min-w-[4rem]">
                <input
                  type="checkbox"
                  id={day}
                  name="days"
                  value={day}
                  checked={businessHours[day].selected}
                  onChange={() => toggleDaySelection(day)}
                  className="w-4 h-4 text-baeonn-600 bg-gray-100 border-gray-300 rounded focus:ring-baeonn-500  focus:ring-2 "
                />
                <label
                  htmlFor={day}
                  className="ms-2 text-sm font-medium text-gray-900 "
                >
                  {day}
                </label>
              </div>

              <div className="w-full max-w-[8rem]">
                <label htmlFor={`start-time-${day}`} className="sr-only">
                  Start time:
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="time"
                    id={`start-time-${day}`}
                    name={`start-time-${day}`}
                    className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    min="09:00"
                    max="18:00"
                    value={businessHours[day].start}
                    onChange={(e) =>
                      handleTimeChange(day, 'start', e.target.value)
                    }
                    required
                  />
                </div>
              </div>
              <div className="w-full max-w-[8rem]">
                <label htmlFor={`end-time-${day}`} className="sr-only">
                  End time:
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="time"
                    id={`end-time-${day}`}
                    name={`end-time-${day}`}
                    className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    min="09:00"
                    max="18:00"
                    value={businessHours[day].end}
                    onChange={(e) =>
                      handleTimeChange(day, 'end', e.target.value)
                    }
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </form>
    </div>
  );
};

export default BaeWeekCalendar;

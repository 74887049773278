import React from 'react';
import MainLayout from '../layout/mainLayout';
import MyBrandPage from '../views/admin/myBrandPage';
import NearBuyStore from '../views/admin/nearBuyStores';
import BusinessDetail from '../views/auth/businessDetail';
import PrintQr from '../views/admin/viewStores/PrintQr';
import AttachQr from '../views/admin/viewStores/AttachQr';
import Analytics from '../views/admin/analytics';
import CreateCampaignView from '../views/admin/campaignCreateView';
import CampaignListView from '../views/admin/viewCampaingsView';
import CampaignDetail from '../views/admin/viewCampaingsView/detail';
import BaeonnCredits from '../views/admin/baeonn-money-transactions/credits';
import BaeonnWallet from '../views/admin/baeonn-money-transactions/wallet';
import CreditAndRef from '../views/admin/creditandref';
import { CampaignActivationView } from '../views/admin/campaignCreateView/CampaignActivation';
import CouponShareV2 from '../views/admin/CouponShare/v2';
import CouponClaimV2 from '../views/admin/claimCoupon/v2';
import Store from '../views/admin/Store';
import ViewStore from '../views/admin/viewStores';
import CreateStore from '../views/admin/Store/CreateStore';
import BusinessDetails from '../views/admin/business/index';

const component = [
  { id: 1.1, path: '/home', element: <Analytics /> },
  { id: 3.1, path: '/create-store', element: <CreateStore /> },
  { id: 3.1, path: '/create-store/add-store', element: <Store /> },
  { id: 3, path: '/store', element: <ViewStore /> },
  { id: 3.1, path: '/store/:store_id', element: <Store /> },
  { id: 4, path: '/business', element: <BusinessDetails /> },
  { id: 8, path: '/claimCoupons', element: <CouponClaimV2 /> },
  { id: 9, path: '/shareCoupons', element: <CouponShareV2 /> },
  { id: 11, path: '/credit', element: <CreditAndRef /> },
  { id: 13, path: '/info', element: <BusinessDetail /> },
  { id: 14, path: '/brandpage', element: <MyBrandPage /> },
  {
    id: 15,
    path: '/nearby',
    element: (
      <MainLayout>
        <NearBuyStore />
      </MainLayout>
    ),
  },
  { id: 16, path: '/print-store-qr', element: <PrintQr /> },
  { id: 17, path: '/attach-qr', element: <AttachQr /> },
  { id: 18, path: '/analytics', element: <Analytics /> },
  { id: 19, path: '/createCampaign', element: <CreateCampaignView /> },
  { id: 20, path: '/viewCampaigns', element: <CampaignListView /> },

  {
    id: 21,
    path: '/campaign-activation/:campaign_id',
    element: <CampaignActivationView />,
  },
  {
    id: 22,
    path: '/viewCampaigns/:campaign_id',
    element: <CampaignDetail />,
  },
  { id: 23, path: '/credit/transactions', element: <BaeonnCredits /> },
  { id: 24, path: '/wallet/transactions', element: <BaeonnWallet /> },
];

export default component;

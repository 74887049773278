import { get } from 'lodash';

const BaePaginationMobile = ({ pages, onPageChange }) => {
  const filteredPages = pages.filter(
    (page) =>
      page.page === 'start' ||
      page.page === 'prev' ||
      page.page === 'next' ||
      page.page === 'end' ||
      page.active
  );

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-2 flex justify-center">
      {filteredPages.map((page, index) => (
        <button
          key={index}
          onClick={() => onPageChange(page.no)}
          disabled={page.disabled}
          className={`mx-1 px-3 py-1 border rounded ${page.active ? 'bg-baeonn-800 text-white' : 'bg-white text-gray-700'} ${page.disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-baeonn-500'}`}
        >
          {typeof page.page === 'string' ? page.page : page.no}
        </button>
      ))}
    </div>
  );
};

const BaePaginationWeb = ({ pages, onPageChange }) => {
  return (
    <div className="pagination flex justify-center mt-4">
      {pages.map((page, index) => (
        <button
          key={index}
          onClick={() => onPageChange(page.no)}
          disabled={page.disabled}
          className={`mx-1 px-3 py-1 border rounded ${page.active ? 'bg-baeonn-800 text-white' : 'bg-white text-gray-700'} ${page.disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-baeonn-500'}`}
        >
          {typeof page.page === 'string' ? page.page : page.no}
        </button>
      ))}
    </div>
  );
};

const BaeTableFilters = ({ setLimit, limit, setFilters, filters, values }) => {
  return (
    <div className="grid md:grid-cols-8 grid-cols-2 gap-4 mb-2">
      <div>
        <label className="text-sm text-gray-700 mr-1">Limit</label>
        <select
          className="border rounded"
          value={limit}
          onChange={(e) => setLimit(Number(e.target.value))}
        >
          {Array.from({ length: 100 }, (_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="text-sm text-gray-700 mr-1">Status</label>
        <select
          className="border rounded"
          value={filters.status}
          onChange={(e) => setFilters({ ...filters, status: e.target.value })}
        >
          {values.map((value, i) => (
            <option key={i} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const BaeTableWeb = ({ headings, rows, paths, handleRowClick }) => {
  return (
    <div>
      <table className="bg-white border border-gray-200 w-full">
        <thead>
          <tr className="text-left">
            {headings?.length > 0 &&
              headings.map((head, i) => (
                <th className="py-2 px-4 border-b" key={i}>
                  {head}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {rows?.length &&
            rows.map((row, i) => {
              return (
                <tr
                  key={row?.id || i}
                  className={`border-b cursor-pointer hover:bg-baeonn-100`}
                  onClick={() => handleRowClick(row)}
                >
                  {paths.map((path, i) => {
                    const value = get(row, path);
                    return (
                      <td key={i} className="py-1 px-4">
                        {value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const BaeTableMobile = ({ headings, rows, paths, handleRowClick }) => {
  return (
    <div>
      <div className="grid gap-4 mb-10">
        {rows?.length > 0 &&
          rows.map((row, i) => (
            <div
              key={row?.id || i}
              className="border rounded p-4 shadow-md"
              onClick={() => handleRowClick(row)}
            >
              {paths.map((path, i) => {
                const value = get(row, path);
                return (
                  <div key={i} className="mb-2">
                    <strong>{headings[i]}:</strong> {value}
                  </div>
                );
              })}
            </div>
          ))}
      </div>
    </div>
  );
};

export {
  BaeTableWeb,
  BaeTableMobile,
  BaePaginationWeb,
  BaePaginationMobile,
  BaeTableFilters,
};

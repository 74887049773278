/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { firstLetterCapital } from './config';
import { get } from 'lodash';

const StoreTableWeb = ({ heading, stores, callback, paths = [] }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowClick = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((rowIndex) => rowIndex !== index)
        : [...prevSelectedRows, index]
    );
  };

  useEffect(() => {
    if (callback) {
      let selectedData = stores.filter((_, i) => selectedRows.includes(i));
      callback(selectedData);
    }
  }, [selectedRows]);

  return (
    <table className="w-full border-collapse border border-gray-300">
      <thead>
        <tr className="bg-gray-200">
          <th className="border border-gray-300 p-1 w-12">Selection</th>
          {heading &&
            heading.map((head, index) => (
              <th key={index} className="border border-gray-300 p-2">
                {head}
              </th>
            ))}
        </tr>
      </thead>
      <tbody className="cursor-pointer">
        {stores &&
          stores.map((store, index) => (
            <tr
              key={index}
              className={`border border-gray-300 ${selectedRows.includes(index) ? 'bg-baeonn-100' : ''}`}
              onClick={() => handleRowClick(index)}
            >
              <td className="border border-gray-300 p-2 text-center">
                {selectedRows.includes(index) && (
                  <FaCheck className="text-baeonn-500" />
                )}
              </td>

              {stores.length &&
                paths.map((path, i) => {
                  const value = get(store, path);
                  return (
                    <td key={i} className="border border-gray-300 p-2 py-4">
                      {typeof value === 'string' && value.startsWith('http') ? (
                        <img
                          src={value}
                          alt="logo"
                          className="h-8 w-8 object-contain"
                        />
                      ) : (
                        value
                      )}
                    </td>
                  );
                })}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

const getLogoUrl = (store, paths, heading) => {
  const logoPath = paths.find((path, i) =>
    heading[i].toLowerCase().includes('logo')
  );
  return get(store, logoPath);
}

const StoreCard = ({ heading, store, index, isSelected, onSelect, paths }) => {

  return (
    <div
      className={`relative border rounded-md p-4 mb-2 ${isSelected ? 'bg-baeonn-100' : ''} shadow-md flex justify-between items-center`}
      onClick={() => onSelect(index)}
    >
      <div className="flex-grow">
        {heading && store && paths.map((path, i) => {
          const value = get(store, path)

          return (
            <div key={i} className="flex items-center justify-between">
              {!heading[i]?.toLowerCase()?.includes('logo') && (
                <p>
                  <span className='font-semibold'>{firstLetterCapital(heading[i])}</span>
                  <span className='ml-2'>:</span>
                  <span className='ml-2'>{value}</span>
                </p>
              )}
            </div>
          );
        })}
      </div>
      {getLogoUrl(store, paths, heading) && (
        <div className="flex-shrink-0 ml-4">
          <img
            src={getLogoUrl(store, paths, heading)}
            alt="logo"
            className="h-12 w-12 object-contain"
          />
        </div>
      )}
      {isSelected && (
        <FaCheck className="text-baeonn-500 ml-2 absolute bottom-2 right-2" />
      )}
    </div>
  );
};

const StoreTableMobile = ({ heading, stores, callback, paths }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelect = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((rowIndex) => rowIndex !== index)
        : [...prevSelectedRows, index]
    );
  };

  useEffect(() => {
    if (callback) {
      let selectedData = stores.filter((_, i) => selectedRows.includes(i));
      callback(selectedData);
    }
  }, [selectedRows]);

  return (
    <div>
      {stores.map((store, index) => (
        <StoreCard
          key={index}
          store={store}
          heading={heading}
          paths={paths}
          index={index}
          isSelected={selectedRows.includes(index)}
          onSelect={handleSelect}
        />
      ))}
    </div>
  );
};

export { StoreTableWeb, StoreTableMobile };

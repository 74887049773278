import { useEffect, useState } from 'react';
import {
  BaeHeading,
  BaeLinkInput,
  BaeNumberInput,
  BaeParagraphInput,
  BaeSmallText,
  BaeTextInput,
  BaeCustomRadioButton,
} from '../../../components/Bae';
import { COUPON_TYPE, URL_REGEX } from './config';
import CampaignDates from './CampaignDates';
import { StoreTableMobile, StoreTableWeb } from './StoreTable';
import { useDispatch, useSelector } from 'react-redux';
import { dealPoolCampaignReducer } from '../../../service_config/configStore';
import * as yup from 'yup';

/**
 * yup validation for deal pool campaign
 */
const campaignSchema = yup.object().shape({
  campaignTitle: yup.string().min(5).required('Campaign title is required'),
  description: yup.string().min(10).required('Description is required'),
  totalCoupons: yup.number().required('Total coupons are required').positive(),
  couponType: yup
    .string()
    .oneOf(Object.values(COUPON_TYPE))
    .required('Coupon type is required'),
  predefinedCoupon: yup.string().when('couponType', {
    is: COUPON_TYPE.PREDEFINED,
    then: yup.string().required('Predefined coupon is required'),
    otherwise: yup.string().nullable(),
  }),
  dates: yup.object().shape({
    startDate: yup.date().required('Start date is required'),
    endDate: yup.date().required('End date is required'),
    expiryDate: yup.date().required('Expiry date is required'),
  }),
  selectedStores: yup
    .array()
    .of(
      yup.object().shape({
        state: yup.string().required('State is required'),
        city: yup.string().required('City is required'),
        zipcode: yup.string().required('Zipcode is required'),
        conversion_rate: yup.number().min(0),
        sharing_rate: yup.number().min(0),
        baeonn_fee: yup.number().min(0),
      })
    )
    .required('Selected stores are required')
    .min(1),
  linkToApp: yup
    .string()
    .nullable()
    .notRequired()
    .matches(URL_REGEX, 'Must be a valid URL'),
  linkToWebsite: yup
    .string()
    .nullable()
    .notRequired()
    .matches(URL_REGEX, 'Must be a valid URL'),
});

const DealPoolCampaign = ({
  campaignForStore,
  mediaPartnerStores,
  createdCampaignHandler,
}) => {
  /**
   * redux hooks
   * used to update the store with the campaign data
   */
  const dispatch = useDispatch();
  const dealPoolData = useSelector(
    (state) => state.campaignAdd.dealPoolCampaign
  );
  const [couponType, setCouponType] = useState(COUPON_TYPE.GENERATE);
  /**
   * selected stores - selected media partners for the campaign
   */
  const [selectedStores, setSelectedStores] = useState([]);
  const [campaignTitle, setCampaignTitle] = useState('');
  const [description, setDescription] = useState('');
  const [totalCoupons, setTotalCoupons] = useState('');
  /**
   * not compulsory - predefined coupon, link to app, link to website
   */
  const [predefinedCoupon, setPredefinedCoupon] = useState('');
  const [linkToApp, setLinkToApp] = useState('');
  const [linkToWebsite, setLinkToWebsite] = useState('');

  const [dates, setDates] = useState({
    startDate: '',
    endDate: '',
    expiryDate: '',
  });

  const dispatchData = () => {
    dispatch(
      dealPoolCampaignReducer({
        campaignTitle,
        description,
        totalCoupons,
        couponType,
        dates,
        selectedStores,
        predefinedCoupon,
        linkToApp,
        linkToWebsite,
      })
    );
  };

  useEffect(() => {
    setCampaignTitle(dealPoolData?.campaignTitle);
    setDescription(dealPoolData?.description);
    setTotalCoupons(dealPoolData?.totalCoupons);
    setPredefinedCoupon(dealPoolData?.predefinedCoupon);
    setLinkToApp(dealPoolData?.linkToApp);
    setLinkToWebsite(dealPoolData?.linkToWebsite);
    setSelectedStores(dealPoolData?.selectedStores);
    setDates(dealPoolData?.dates);
  }, []);

  useEffect(() => {
    dispatchData(); //used to prefil data on tab change

    const checkData = {
      campaignTitle,
      description,
      totalCoupons,
      couponType,
      dates,
      selectedStores,
      ...(predefinedCoupon && { predefinedCoupon }),
      ...(linkToApp && { linkToApp }),
      ...(linkToWebsite && { linkToWebsite }),
    };

    if (couponType !== COUPON_TYPE.PREDEFINED) {
      delete checkData.predefinedCoupon;
    }

    campaignSchema
      .validate(checkData, { abortEarly: false })
      .then(() => {
        createdCampaignHandler(checkData);
      })
      .catch((_) => {
        createdCampaignHandler(null);
      });
  }, [
    campaignTitle,
    description,
    totalCoupons,
    couponType,
    dates,
    selectedStores,
    predefinedCoupon,
    linkToApp,
    linkToWebsite,
  ]);

  return (
    <div>
      <div className="py-2">
        <BaeTextInput
          placeholder="Campaign title"
          required={true}
          minLength={5}
          callback={(data) => setCampaignTitle(data)}
          value={campaignTitle}
        />
      </div>

      <div className="py-2">
        <BaeParagraphInput
          placeholder="Campaign description"
          required={true}
          minLength={10}
          callback={(data) => setDescription(data)}
          value={description}
        />
      </div>

      {/* above para input causing extra padding at its bottom hence custom padding is set to overcome the issue */}
      <div className="pb-2 py-1">
        <BaeNumberInput
          placeholder="No of coupons"
          required={true}
          min={1}
          max={1000}
          callback={(data) => setTotalCoupons(data)}
          value={totalCoupons}
        />
        <BaeSmallText>*Affects final pricing.</BaeSmallText>
      </div>

      <div className="py-2">
        <BaeHeading size="text-2xl">Coupon Type</BaeHeading>
        <p>
          <BaeSmallText>Generate will assign coupon code.</BaeSmallText>
        </p>
        <p>
          <BaeSmallText>Predefined is custom coupon code.</BaeSmallText>
        </p>
        <div className="pt-2">
          <BaeCustomRadioButton
            options={[COUPON_TYPE.GENERATE, COUPON_TYPE.PREDEFINED]}
            callback={(type) => setCouponType(type)}
          />
        </div>
      </div>

      <div className="py-2">
        <div
          className={`grid md:grid-cols-${couponType === COUPON_TYPE.PREDEFINED ? 3 : 2} gap-4`}
        >
          {couponType === COUPON_TYPE.PREDEFINED && (
            <div>
              <BaeHeading size="text-2xl">Enter Alphanumeric Coupon</BaeHeading>
              <div className="pt-2">
                <BaeTextInput
                  placeholder="e.g SUPER200. Spaces not allowed"
                  required={true}
                  minLength={5}
                  nospace={true}
                  callback={(data) => setPredefinedCoupon(data)}
                  value={predefinedCoupon}
                />
              </div>
            </div>
          )}
          <div>
            <BaeHeading size="text-2xl">Link to the app</BaeHeading>
            <div className="pt-2">
              <BaeLinkInput
                placeholder="App link"
                callback={(data) => setLinkToApp(data)}
                value={linkToApp}
              />
            </div>
          </div>

          <div>
            <BaeHeading size="text-2xl">Link to the website</BaeHeading>
            <div className="pt-2">
              <BaeLinkInput
                placeholder="Website link"
                callback={(data) => setLinkToWebsite(data)}
                value={linkToWebsite}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="py-2">
        <CampaignDates callback={(data) => setDates(data)} />
      </div>

      {/* when a campaign store selected then show media partner stores */}
      {mediaPartnerStores &&
        mediaPartnerStores.length > 0 &&
        campaignForStore && (
          <div className="py-2">
            <BaeHeading size="text-2xl">Select Pincodes</BaeHeading>
            <BaeSmallText>
              Pincodes where campaign will be visible to other media partners.
            </BaeSmallText>

            <span className="hidden md:block">
              <BaeSmallText>Click to select.</BaeSmallText>
            </span>

            <div className="md:hidden">
              <BaeSmallText>Tap to select.</BaeSmallText>
            </div>

            <div className="pt-2">
              {/* web view */}
              <div className="hidden md:block">
                <StoreTableWeb
                  heading={['State', 'City', 'Pincode']}
                  paths={['state', 'city', 'zipcode']}
                  stores={mediaPartnerStores}
                  callback={(stores) => setSelectedStores(stores)}
                />
              </div>
              {/* mobile view */}
              <div className="md:hidden">
                <StoreTableMobile
                  heading={['State', 'City', 'Pincode']}
                  stores={mediaPartnerStores}
                  callback={(stores) => setSelectedStores(stores)}
                  paths={['state', 'city', 'zipcode']}
                />
              </div>
            </div>
            <BaeSmallText>*Affects final pricing.</BaeSmallText>
          </div>
        )}
    </div>
  );
};

export default DealPoolCampaign;

import axios from 'axios';
import { getCurrentUser } from './auth.service';

const AXIOS = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export function get(url) {
  if (getCurrentUser() && getCurrentUser()?.authToken) {
    AXIOS.defaults.headers.common['Authorization'] =
      `Bearer ${getCurrentUser().authToken}`;
  }
  return AXIOS.get(url);
}

export function post(url, payload) {
  if (getCurrentUser() && getCurrentUser()?.authToken) {
    AXIOS.defaults.headers.common['Authorization'] =
      `Bearer ${getCurrentUser().authToken}`;
  }
  return AXIOS.post(url, payload);
}

export function put(url, payload) {
  if (getCurrentUser() && getCurrentUser()?.authToken) {
    AXIOS.defaults.headers.common['Authorization'] =
      `Bearer ${getCurrentUser().authToken}`;
  }
  return AXIOS.put(url, payload);
}
export function patch(url, payload) {
  if (getCurrentUser() && getCurrentUser()?.authToken) {
    AXIOS.defaults.headers.common['Authorization'] =
      `Bearer ${getCurrentUser().authToken}`;
  }
  return AXIOS.patch(url, payload);
}

export function dlt(url, payload) {
  if (getCurrentUser() && getCurrentUser()?.authToken) {
    AXIOS.defaults.headers.common['Authorization'] =
      `Bearer ${getCurrentUser().authToken}`;
  }
  return AXIOS.delete(url, payload);
}

export function FILE_UPLOAD(url, data, options) {
  if (getCurrentUser() && getCurrentUser()?.authToken) {
    AXIOS.defaults.headers.common['Authorization'] =
      `Bearer ${getCurrentUser().authToken}`;
  }
  return AXIOS.post(url, data, options);
}

import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../context/UserContext';

const Initials = (text) => {
  return text && text.length && text[0].toUpperCase();
};

const ProfilePopupButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);
  const {
    user: { user },
    deleteUser,
  } = useContext(UserContext);
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative inline-block text-left" ref={popupRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center rounded-full bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="mr-2 h-5 w-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 7.5C15.75 9.985 13.735 12 11.25 12S6.75 9.985 6.75 7.5 8.765 3 11.25 3s4.5 2.015 4.5 4.5zM4.5 20.25a6.75 6.75 0 0113.5 0v.375a.375.375 0 01-.375.375H4.875a.375.375 0 01-.375-.375v-.375z"
          />
        </svg>
        <span className="truncate">
          {Initials(user?.firstName)}
          {Initials(user?.lastName)}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="ml-2 h-4 w-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 9l-7.5 7.5L4.5 9"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-4 py-3">
            <p className="text-sm text-gray-500">Signed in as</p>
            <p className="truncate text-sm font-medium text-gray-900">
              {user?.email}
            </p>
          </div>
          <div className="py-1">
            <button className="group flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mr-3 h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 5.25V18.75C3 19.9926 4.00736 21 5.25 21H18.75C19.9926 21 21 19.9926 21 18.75V5.25C21 4.00736 19.9926 3 18.75 3H5.25C4.00736 3 3 4.00736 3 5.25ZM12 18.75C11.5858 18.75 11.25 18.4142 11.25 18C11.25 17.5858 11.5858 17.25 12 17.25C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75ZM6.75 4.5H17.25V15.75H6.75V4.5Z"
                />
              </svg>
              {user?.mobileNumber}
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                deleteUser();
              }}
              className="group flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mr-3 h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 9V5.25m0 0a2.25 2.25 0 10-4.5 0V9m4.5 0H9.75m1.5 6.75h2.25m-5.625 0h9"
                />
              </svg>
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePopupButton;

import { useEffect, useState } from 'react';
import {
  BaeButton,
  BaeHeading,
  BaeHorizontalLine,
  BaeSmallText,
  BaeSmallTextButton,
  BaeSuccessText,
  BaeTermsAndConditionButton,
  BaeWarningText,
} from '../../../components/Bae';
import {
  activateCampaign,
  getCamapignPaymentQuote,
  getCanActivateCampaign,
} from '../../../service_config/admin.service';
import toast from 'react-hot-toast';
import MainLayout from '../../../layout/mainLayout';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * CampaignActivationView
 * will be used to show the payment details of the campaign
 * this will be used as a route component in the admin.routes.js
 */
const CampaignActivationView = () => {
  const { campaign_id } = useParams();
  // eslint-disable-next-line prettier/prettier
  const nextStepHandler = () => { };


  return (
    <MainLayout>
      <div>
        <CampaignActivation
          campaign_id={campaign_id}
          nextStepHandler={nextStepHandler}
        />
      </div>
    </MainLayout>
  );
};

/**
 * CampaignActivation
 * will be used to show the payment details of the campaign
 */
const CampaignActivation = ({ campaign_id, nextStepHandler }) => {
  const navigate = useNavigate();
  const [credit, setCredit] = useState(0);
  const [quote, setQuote] = useState(0);
  const [isError, setIsError] = useState(false);
  const [canActivateData, setCanActivateData] = useState('');

  const getQuote = () => {
    getCamapignPaymentQuote(campaign_id, credit)
      .then((response) => {
        setQuote(response.result);
      })
      .catch((error) => {
        setIsError(true);
      });
  };

  const canActivate = () => {
    getCanActivateCampaign(campaign_id, credit)
      .then((response) => {
        setCanActivateData(response.result);
      })
      .catch((_) => {
        // eslint-disable-next-line no-console
        console.log(_);
      });
  };

  useEffect(() => {
    getQuote();
    canActivate();
  }, [credit]);

  const useCreditHandler = (e) => {
    e.preventDefault();
    /**
     * for now using all credits we can
     * later we can add a input field to take the credits
     * if credit state changes then we will make call again to get the quote
     */
    if (credit === 0) {
      setCredit(quote?.credits?.allowed);
    } else {
      setCredit(0);
    }
  };

  const activateCampaignHandler = (e) => {
    e.preventDefault();
    activateCampaign(campaign_id, credit)
      .then((_) => {
        if (_.result.msg) {
          toast.success(_.result.msg);
        }
        navigate(`/viewCampaigns/${campaign_id}`);
      })
      .catch((_) => {
        if (_.msg) {
          toast.error(_.msg);
        }
      });
  };

  return (
    <div>
      <BaeHeading size="text-4xl">Activate</BaeHeading>

      <div className="py-2">
        <BaeHeading size="text-2xl">Campaign Details</BaeHeading>
      </div>

      <div className="py-2">
        <BaeSuccessText>
          <BaeSmallText>
            Congratulations on creating a campaign! Please proceed with the
            activation to make it live.
          </BaeSmallText>
        </BaeSuccessText>
      </div>

      <div className="py-2">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <BaeSmallText>Title</BaeSmallText>
            <p className="font-semibold">{quote?.campaign?.title}</p>
          </div>
          <div>
            <BaeSmallText>Description</BaeSmallText>
            <p className="font-semibold">{quote?.campaign?.description}</p>
          </div>
          <div>
            <BaeSmallText># of coupons</BaeSmallText>
            <p className="font-semibold">{quote?.campaign?.total_coupons}</p>
          </div>
        </div>
      </div>

      <div className="py-2">
        <BaeHorizontalLine />
      </div>

      <div className="py-2">
        <BaeHeading size="text-2xl">Payment Details</BaeHeading>
        <BaeSmallText>
          *Will be deducted from wallet as the campaign progress.
        </BaeSmallText>
      </div>

      <div className="py-2">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div>
            <BaeSmallText>Amount</BaeSmallText>
            <p className="font-semibold">
              {quote?.payment?.currency}
              {quote?.payment?.cash}
            </p>
          </div>
          <div>
            <BaeSmallText>Baeon fee</BaeSmallText>
            <p className="font-semibold">
              {quote?.payment?.currency}
              {quote?.payment?.baeonn_commision}
            </p>
          </div>
          <div>
            <BaeSmallText>GST (18%)</BaeSmallText>
            <p className="font-semibold">
              {quote?.payment?.currency}
              {quote?.payment?.gst}
            </p>
          </div>
          <div>
            <BaeSmallText>Credits utilization</BaeSmallText>
            <p className="font-semibold">{quote?.payment?.credits}</p>
          </div>
          <div>
            <BaeSmallText>Credit balance</BaeSmallText>
            {quote?.credits?.enabled && (
              <p>
                <span className="font-semibold">{quote?.credits?.allowed}</span>
                <span> </span>
                {credit === 0 ? (
                  <BaeSmallTextButton onClick={useCreditHandler}>
                    use
                  </BaeSmallTextButton>
                ) : (
                  <BaeSmallTextButton onClick={useCreditHandler}>
                    remove
                  </BaeSmallTextButton>
                )}
              </p>
            )}
            {!quote?.credits?.enabled && (
              <p>
                <span className="font-semibold">{quote?.credits?.allowed}</span>
                <span> </span>
                <BaeSmallText>Low</BaeSmallText>
              </p>
            )}
          </div>
          <div>
            <BaeSmallText>Total</BaeSmallText>
            <p className="font-semibold">
              {quote?.payment?.currency}
              {quote?.payment?.total}
            </p>
          </div>
        </div>
      </div>

      {credit > 0 && (
        <div className="py-2">
          <BaeSuccessText>
            <BaeSmallText>
              Credits applied successfully! You saved {quote?.payment?.currency}
              {quote?.payment?.saved}.
            </BaeSmallText>
          </BaeSuccessText>
        </div>
      )}

      {!canActivateData?.can_activate && (
        <div className="py-2">
          <BaeWarningText>
            <BaeSmallText>{canActivateData?.msg}</BaeSmallText>
            <div className="mr-1"></div>
          </BaeWarningText>
        </div>
      )}

      {canActivateData?.can_activate && canActivateData?.msg && (
        <div className="py-2">
          <BaeWarningText>
            <BaeSmallText>{canActivateData?.msg}</BaeSmallText>
            <div className="mr-1"></div>
          </BaeWarningText>
        </div>
      )}

      <div className="py-2">
        <BaeHorizontalLine />
      </div>

      <div className="py-2">
        <BaeTermsAndConditionButton />
      </div>

      <div className="py-2">
        {quote?.payment?.total > 0 ? (
          <BaeButton
            onClick={activateCampaignHandler}
            disable={!canActivateData?.can_activate}
          >
            Activate campaign
          </BaeButton>
        ) : (
          <BaeButton
            onClick={activateCampaignHandler}
            disable={!canActivateData?.can_activate}
            type="secondary"
          >
            Activate free campaign
          </BaeButton>
        )}
      </div>
    </div>
  );
};

export { CampaignActivation, CampaignActivationView };

import moment from 'moment';
import { useEffect, useState } from 'react';
import { BaeDateInput } from '../../../components/Bae';

const FORMAT = 'YYYY-MM-DD';

const CampaignDates = ({ callback }) => {
  /**
   * start data = today's date
   * end date = start date + 1 week (7 days)
   * expiry date = end date + 1 week (7 days)
   */
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  useEffect(() => {
    setStartDate(moment().format(FORMAT));
  }, []);

  useEffect(() => {
    if (moment(endDate, FORMAT).diff(moment(startDate), 'days') >= 7) {
      return;
    }
    setEndDate(moment(startDate, FORMAT).add(1, 'week').format(FORMAT));
  }, [startDate]);

  useEffect(() => {
    if (moment(expiryDate, FORMAT).diff(moment(endDate), 'days') >= 7) {
      return;
    }
    setExpiryDate(moment(endDate, FORMAT).add(1, 'week').format(FORMAT));
  }, [endDate]);

  useEffect(() => {
    callback &&
      callback({
        startDate: moment(startDate).format(FORMAT),
        endDate: moment(endDate).format(FORMAT),
        expiryDate: moment(expiryDate).format(FORMAT),
      });
  }, [startDate, endDate, expiryDate]);

  return (
    <div className="grid md:grid-cols-3 gap-4">
      <BaeDateInput
        placeholder="Start Date"
        required={true}
        min={moment().format(FORMAT)}
        callback={(data) => setStartDate(data)}
        value={startDate}
      />
      <BaeDateInput
        placeholder="End Date"
        required={true}
        min={moment(startDate).add(1, 'week').format(FORMAT)}
        value={endDate}
        callback={(data) => setEndDate(data)}
      />
      <BaeDateInput
        placeholder="Expiry Date"
        required={true}
        min={moment(endDate).add(1, 'week').format(FORMAT)}
        value={expiryDate}
        callback={(data) => setExpiryDate(data)}
      />
    </div>
  );
};

export default CampaignDates;

import { FaCheck } from 'react-icons/fa';
import { BaeButton } from '../../../components/Bae';
import { useNavigate } from 'react-router-dom';

const CampaignComplete = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-col items-center justify-center">
        <div className="bg-baeonn-500 rounded-full p-6 mb-4">
          <FaCheck className="text-white text-9xl" />
        </div>
        <h1 className="md:text-3xl font-bold text-gray-900">
          Campaign successfully created
        </h1>
      </div>

      <div className="py-4">
        <BaeButton onClick={() => navigate('/home')}>Done</BaeButton>
      </div>
    </div>
  );
};

export default CampaignComplete;

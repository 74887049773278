/**
 * create new store
 * view the store id store_id
 */
import { useContext, useEffect, useState } from 'react';
import {
  BaeButton,
  BaeEmailInput,
  BaeHeading,
  BaeLinkButton,
  BaeLoadingButton,
  BaeNumberInput,
  BaeTextInput,
  BaeToggle,
} from '../../../components/Bae';
import BaeDragAndDropUpload from '../../../components/Bae/BaeDragAndDropUpload';
import BaeDropdown from '../../../components/Bae/BaeDropdown';
import BaeWeekCalendar from '../../../components/Bae/BaeWeekCalendar';
import MainLayout from '../../../layout/mainLayout';
import toast from 'react-hot-toast';
import { getLocation, weekNames } from '../../../utilities/commonFunction';
import {
  getCategoryLists,
  getCityOrStates,
  getCityStateByPincode,
  getStoreDetailById,
  submitStoreDetail,
  updateStoreDetailById,
} from '../../../service_config/admin.service';
import * as yup from 'yup';
import FileUploadHoc from '../../../components/File.Upload';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import { UserContext } from '../../../context/UserContext';

const createStorevalidationSchema = yup.object().shape({
  branch_name: yup
    .string()
    .min(3, 'Branch name must be at least 3 characters')
    .required('Branch name is required'),
  category_id: yup.string().required('Category is required'),
  category: yup.string().required('Category is required'),
  number_of_bills: yup.number().required('Number of bills is required'),
  avg_bill_value: yup.number().required('Avg bill value is required'),
  location: yup.object().shape({
    latitude: yup.string().required('Latitude is required'),
    longitude: yup.string().required('Longitude is required'),
  }),
  pincode: yup
    .string()
    .length(6, 'Pincode must be exactly 6 characters')
    .required('Pincode is required'),
  state_id: yup.string().required('State is required'),
  state: yup.string().required('State is required'),
  city_id: yup.string().required('City is required'),
  city: yup.string().required('City is required'),
  store_email: yup.string().email().required('Store email is required'),
  customer_care_contact: yup
    .string()
    .min(10, 'Customer care contact must be at least 10 characters')
    .required('Customer care contact is required'),
  store_image: yup.string(),
  store_logo: yup.string(),
  week_calendar: yup.object().shape({
    [yup.string().oneOf(weekNames)]: yup.object().shape({
      start: yup.string(),
      end: yup.string(),
      selected: yup.boolean(),
    }),
  }),
});

const Store = () => {
  const navigate = useNavigate();
  // const userDetail = useSelector((state) => state?.userDetail?.userDetail);

  const {
    user: { user: userDetail },
  } = useContext(UserContext);

  const { store_id } = useParams();
  /**
   * same as the yup schema
   */
  const [formData, setFormData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [locationAttempt, setLocationAttempt] = useState(0);
  const [categories, setCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [disableSubmit, setDisableSubmit] = useState(true);

  const [weekCalendar, setWeekCalendar] = useState(null);

  /**
   * used to re-render the dropdown
   * key for state 0
   * key for city 1000
   * this is kept to make diff key for both dropdown else it will not re-render and duplicate values will be shown
   */
  const [stateDropdownKey, setStateDropdownKey] = useState(0);
  const [cityDropdownKey, setCityDropdownKey] = useState(1000);

  /**
   * toggles
   */
  const [sameEmail, setSameEmail] = useState(false);
  const [sameNumber, setSameNumber] = useState(false);

  /**
   * context
   */
  const { _, updateData } = useContext(AppContext);

  const isSameEmail = () => {
    return userDetail?.email === formData?.store_email;
  };

  const isSameNumber = () => {
    return userDetail?.mobileNumber === formData?.customer_care_contact;
  };

  const getStatesHandler = () => {
    setStateDropdownKey((v) => v + 1);
    getCityOrStates(null, false)
      .then((response) => {
        setStates(response.result);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getCitiesHandler = (state_id) => {
    setCityDropdownKey((v) => v + 1);
    getCityOrStates(state_id, false)
      .then((response) => {
        setCities(response.result);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  /**
   * create store handler
   * @param {*} e
   */
  const onSubmitHandler = (e) => {
    e.preventDefault();
    submitStoreDetail(formData)
      .then((_) => {
        toast.success('Store created successfully');
        const created_store_id = _.result.id;
        navigate(`/store/${created_store_id}`);
        updateData({ disableSidebarMenuButtons: false });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  /**
   * update store handler
   * @param {*} e
   */
  const onUpdateHandler = (e) => {
    e.preventDefault();
    updateStoreDetailById({ id: store_id, body: formData })
      .then((response) => {
        toast.success(response?.result?.msg);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const checkIsEmailSame = () => {
    if (
      store_id &&
      userDetail?.email &&
      formData?.store_email &&
      isSameEmail()
    ) {
      setSameEmail(true);
    }

    if (
      store_id &&
      userDetail?.mobileNumber &&
      formData?.customer_care_contact &&
      isSameNumber()
    ) {
      setSameNumber(true);
    }
  };

  useEffect(() => {
    if (!isSameEmail()) {
      setSameEmail(false);
    }
    if (!isSameNumber()) {
      setSameNumber(false);
    }
  }, [formData.store_email, formData.customer_care_contact]);

  useEffect(() => {
    if (store_id && userDetail && formData) {
      checkIsEmailSame();
    }
  }, [userDetail, formData, store_id]);

  useEffect(() => {
    if (sameEmail) {
      setFormData({
        ...formData,
        store_email: userDetail?.email,
      });
    }

    if (sameNumber) {
      setFormData({
        ...formData,
        customer_care_contact: userDetail?.mobileNumber,
      });
    }
  }, [sameEmail, sameNumber]);

  useEffect(() => {
    createStorevalidationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setDisableSubmit(false);
      })
      .catch((_) => {
        setDisableSubmit(true);
      });
  }, [formData]);

  useEffect(() => {
    getCategoryLists()
      .then((response) => {
        setCategories(response.result);
      })
      .catch((error) => {
        toast.error(error.message);
      });

    getStatesHandler();
  }, []);

  useEffect(() => {
    setCities(null);
    if (formData.state_id) {
      getCitiesHandler(formData.state_id);
    }
  }, [formData.state_id]);

  /**
   * if store_id is passed then get the store details
   * this will be used to update the store and not create
   */
  useEffect(() => {
    if (store_id) {
      getStoreDetailById({ id: store_id })
        .then((response) => {
          if (response.result) {
            setFormData(response.result);
            setWeekCalendar(response.result.week_calendar);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }, [store_id]);

  const handleFilesAdded = async (location, type) => {
    if (type === 'storeImage') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        store_image: location,
      }));
    }
    if (type === 'storeLogo') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        store_logo: location,
      }));
    }
  };

  const handleGetLocation = async () => {
    try {
      setIsLoading(true);
      const location = await getLocation();
      setFormData({ ...formData, location });
      setIsLoading(false);
      setLocationAttempt(0);
    } catch (error) {
      setLocationAttempt((locationAttempt) => locationAttempt + 1);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  /**
   * checks for state and city based on pincode
   * if not found then get the states
   * if found then set the state and city
   */
  useEffect(() => {
    if (formData?.pincode?.length === 6) {
      getCityStateByPincode(formData?.pincode, false)
        .then((response) => {
          if (response?.result?.state && response?.result?.city) {
            setStates(response.result.state);
            setCities(response.result.city);
            setStateDropdownKey((v) => v + 1);
            setCityDropdownKey((v) => v + 1);
            setFormData({
              ...formData,
              city: response.result.city[0].city_name,
              city_id: response.result.city[0].city_id,
              state: response.result.state[0].state_name,
              state_id: response.result.state[0].state_id,
            });
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      if (states.length <= 1) {
        getStatesHandler();
      }
    }
  }, [formData?.pincode]);

  return (
    <MainLayout>
      <div>
        <BaeHeading>Store</BaeHeading>

        <BaeTextInput
          placeholder={'Branch name'}
          className={'mt-6'}
          required={true}
          callback={(value) => setFormData({ ...formData, branch_name: value })}
          value={formData?.branch_name}
        />
        <BaeDropdown
          rows={categories}
          field={'name'}
          className={'mt-6'}
          selectText="Select Category"
          callback={(value) =>
            setFormData({
              ...formData,
              category: value.name,
              category_id: value.id,
            })
          }
          value={formData.category}
        />

        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-6">
          <BaeNumberInput
            placeholder={'Number of bills per day'}
            required={true}
            callback={(value) =>
              setFormData({ ...formData, number_of_bills: value })
            }
            value={formData?.number_of_bills}
          />
          <BaeNumberInput
            placeholder={'Avg bill value'}
            required={true}
            callback={(value) =>
              setFormData({ ...formData, avg_bill_value: value })
            }
            value={formData?.avg_bill_value}
          />
        </div>

        <div className={'mt-6'}>
          <BaeHeading>Outlet Address</BaeHeading>
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-6">
          {formData?.location?.latitude == 0 || !formData.location ? (
            locationAttempt >= 3 ? (
              <BaeTextInput
                placeholder={'Paste your location: 29.00, 29.00'}
                callback={(value) => {
                  const [latitude, longitude] = value.split(',');
                  setFormData({
                    ...formData,
                    location: { latitude, longitude },
                  });
                }}
                required={true}
              />
            ) : (
              <BaeLoadingButton
                onClick={handleGetLocation}
                isLoading={isLoading}
              >
                Get location
              </BaeLoadingButton>
            )
          ) : (
            <div className="grid grid-cols-2 gap-4">
              <BaeLinkButton
                value={
                  formData?.location?.latitude != 0
                    ? `https://www.google.com/maps?q=${formData.location.latitude},${formData.location.longitude}`
                    : ''
                }
              >
                See on maps
              </BaeLinkButton>
              <BaeButton
                onClick={() => {
                  setFormData({ ...formData, location: null });
                  setLocationAttempt(0);
                }}
              >
                Reset map
              </BaeButton>
            </div>
          )}
          <BaeNumberInput
            placeholder={'Pincode'}
            required={true}
            callback={(v) => setFormData({ ...formData, pincode: v })}
            value={formData?.pincode}
          />
          <div key={stateDropdownKey}>
            <BaeDropdown
              key={stateDropdownKey}
              rows={states}
              field={'state_name'}
              selectText="Select State"
              callback={(value) =>
                setFormData({
                  ...formData,
                  state: value.state_name,
                  state_id: value.state_id,
                })
              }
              value={formData.state}
            />
          </div>
          <div key={cityDropdownKey}>
            <BaeDropdown
              key={cityDropdownKey}
              rows={cities}
              field={'city_name'}
              selectText="Select City"
              callback={(value) =>
                setFormData({
                  ...formData,
                  city: value.city_name,
                  city_id: value.city_id,
                })
              }
              value={formData.city}
            />
          </div>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1 gap-4 mt-6">
          <div className="col-span-6">
            <BaeToggle
              label={'Same as bussiness owner email?'}
              onToggle={(_) => {
                setSameEmail((v) => !v);
              }}
              isOn={sameEmail}
            />
            <BaeEmailInput
              placeholder={'Store Email'}
              required={true}
              callback={(v) => setFormData({ ...formData, store_email: v })}
              value={sameEmail ? userDetail?.email : formData?.store_email}
            />
          </div>
          <div className="col-span-6">
            <BaeToggle
              label={'Same as bussiness owner number?'}
              onToggle={(_) => {
                setSameNumber((v) => !v);
              }}
              isOn={sameNumber}
            />
            <BaeTextInput
              placeholder={'Customer care contact'}
              required={true}
              callback={(value) =>
                setFormData({ ...formData, customer_care_contact: value })
              }
              value={
                sameNumber
                  ? userDetail?.mobileNumber
                  : formData?.customer_care_contact
              }
            />
          </div>
        </div>

        <div
          className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-6"
          key={'storeImageLogo'}
        >
          <FileUploadHoc
            fileType={'storeImage'}
            set={(l, t) => handleFilesAdded(l, t)}
          >
            <BaeDragAndDropUpload
              label={'Upload store image'}
              fileLimit={1}
              id="storeImage"
              image={formData?.store_image}
            />
          </FileUploadHoc>

          <FileUploadHoc
            fileType={'storeLogo'}
            set={(l, t) => handleFilesAdded(l, t)}
          >
            <BaeDragAndDropUpload
              label={'Upload logo'}
              fileLimit={1}
              id="storeLogo"
              image={formData?.store_logo}
            />
          </FileUploadHoc>
        </div>

        <div className="mt-6">
          <BaeWeekCalendar
            callback={(value) =>
              setFormData({ ...formData, week_calendar: value })
            }
            value={weekCalendar}
          />
        </div>

        {!store_id ? (
          <BaeButton
            className={'mt-6'}
            disable={disableSubmit}
            onClick={onSubmitHandler}
          >
            Create Store
          </BaeButton>
        ) : (
          <BaeButton
            className={'mt-6'}
            disable={disableSubmit}
            onClick={onUpdateHandler}
          >
            Update Store
          </BaeButton>
        )}
      </div>
    </MainLayout>
  );
};

export default Store;

import React, { useContext, useState } from 'react';
import MainLayout from '../../layout/mainLayout';

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { getBaeonnCredits } from '../../service_config/admin.service';
import { Link } from 'react-router-dom';
import {
  BaeCopyTextInput,
  BaeHeading,
  BaeSmallText,
} from '../../components/Bae';
import { UserContext } from '../../context/UserContext';
import toast from 'react-hot-toast';

export default function CreditAndRef() {
  const [credits, setCredits] = useState(0);
  const [businessId, setBusinessId] = useState(null);

  const {
    user: { user },
  } = useContext(UserContext);

  const GETURL = () => {
    return `${process.env.REACT_APP_WEB_BASE_URL}login?refUser=${user?.id}&refCode=${user?.referralCode}`;
  };

  const title = 'Acquire and retain customers at ease with BAEON.';
  const hashtag = 'baeon';

  React.useEffect(() => {
    setBusinessId(user.businessId);
  }, []);

  React.useEffect(() => {
    if (!businessId) return;
    getBaeonnCredits(businessId, 1, 1, 'all')
      .then((res) => {
        setCredits(res.result.total_remaining);
      })
      .catch((_) => {
        toast.error('Error fetching credits');
      });
  }, [businessId]);

  return (
    <>
      <MainLayout>
        <div>
          <BaeHeading>Credit and Referral</BaeHeading>

          <div className="mt-6"></div>

          <div className="mt-6">
            <BaeSmallText>Total Point Earned</BaeSmallText>
            <p className="text-4xl text-baeonn-800 font-extrabold">{credits}</p>
            <Link
              to="/credit/transactions"
              className="text-baeonn-800 hover:underline"
            >
              See more
            </Link>
          </div>

          <div className="mt-6">
            <BaeSmallText>
              Your referral link, share with your friends and earn baeonn
              credits
            </BaeSmallText>
            <BaeCopyTextInput text={GETURL()} />
          </div>

          {/* shaer via buttons */}
          <div className="mt-6">
            <BaeHeading>Social media buttons</BaeHeading>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-6">
            <div className="flex justify-center">
              <FacebookShareButton
                url={GETURL()}
                hashtag={hashtag}
                quote={title}
                className="shareButton "
              >
                <FacebookIcon size={100} round />
              </FacebookShareButton>
            </div>
            <div className="flex justify-center">
              {' '}
              <TwitterShareButton
                url={GETURL()}
                hashtags={[hashtag]}
                title={title}
                className="shareButton "
              >
                <TwitterIcon size={100} round />
              </TwitterShareButton>
            </div>
            <div className="flex justify-center">
              {' '}
              <TelegramShareButton
                url={GETURL()}
                title={title}
                className="shareButton"
              >
                <TelegramIcon size={100} round />
              </TelegramShareButton>
            </div>
            <div className="flex justify-center">
              {' '}
              <WhatsappShareButton
                url={GETURL()}
                title={title}
                separator=":: "
                className="shareButton"
              >
                <WhatsappIcon size={100} round />
              </WhatsappShareButton>
            </div>
            <div className="flex justify-center">
              <LinkedinShareButton url={GETURL()} className="shareButton">
                <LinkedinIcon size={100} round />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
}

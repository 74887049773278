/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { FaCheck, FaExclamation, FaTimes } from 'react-icons/fa';
const URL_REGEX = /^(https?:\/\/)?(www\.)?[^\s]+?\.[a-zA-Z]{2,63}(\/[^\s]*)?$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const BaeTextInput = ({ placeholder, required = false, minLength, maxLength, nospace = false, callback, value, className, disable = false }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    const handleChange = (event) => {
        event.preventDefault();
        if (nospace && event.target.value.includes(' ')) {
            //remove space
            event.target.value = event.target.value.replace(/\s/g, '');
        }
        callback && callback(event.target.value);
    };

    const check = (value) => {
        if (value === '' && required) {
            setIsInvalid(true);
            return;
        }

        if (value === '' && !required) {
            setIsInvalid(false);
            return;
        }

        if (value !== '' && value.length < minLength) {
            setIsInvalid(true);
            return;
        }

        if (value !== '' && value.length > maxLength) {
            setIsInvalid(true);
            return;
        }

        setIsInvalid(false);
    }

    const handleBlur = (e) => {
        const value = e.target.value;
        setIsFocused(value === '');
        check(value);
    };

    return (
        <div>
            <input
                type="text"
                className={`border rounded-md p-2 py-4 w-full shadow-md ${className} ${isInvalid ? 'border-red-500' : 'border-gray-300'
                    }`}
                placeholder={placeholder || 'Enter text here'}
                required={required}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                {...(minLength !== undefined ? { minLength } : {})}
                {...(maxLength !== undefined ? { maxLength } : {})}
                onChange={handleChange}
                value={value}
                disabled={disable}
            />
        </div>
    );
};

const BaeParagraphInput = ({
    placeholder,
    required = false,
    minLength,
    maxLength,
    callback,
    value,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);


    const check = (value) => {
        if (value === '' && required) {
            setIsInvalid(true);
            return;
        }

        if (value === '' && !required) {
            setIsInvalid(false);
            return;
        }

        if (value !== '' && value.length < minLength) {
            setIsInvalid(true);
            return;
        }

        if (value !== '' && value.length > maxLength) {
            setIsInvalid(true);
            return;
        }

        setIsInvalid(false);
    }

    const handleBlur = (e) => {
        const value = e.target.value;
        setIsFocused(value === '');
        check(value);
    };

    const handleChange = (event) => {
        event.preventDefault();
        callback && callback(event.target.value);
    };

    return (
        <div>
            <textarea
                className={`border rounded-md p-2 py-4 w-full shadow-md ${(required && isFocused) || isInvalid ? 'border-red-500' : 'border-gray-300'
                    }`}
                // className={clsx(
                //     'border rounded-md p-2 py-4 w-full shadow-md',
                //     {
                //         'border-red-500': (required && isFocused) || isInvalid,
                //         'border-gray-300': !((required && isFocused) || isInvalid),
                //     }
                // )}

                placeholder={placeholder || 'Enter paragraph here'}
                rows="2"
                required={required}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                {...(minLength !== undefined ? { minLength } : {})}
                {...(maxLength !== undefined ? { maxLength } : {})}
                onChange={handleChange}
                value={value}
            />
        </div>
    );
};

const BaeNumberInput = ({ placeholder, required = false, min, max, callback, value }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    const check = (value) => {
        if (value === '' && required) {
            setIsInvalid(true);
            return;
        }

        if (value === '' && !required) {
            setIsInvalid(false);
            return;
        }

        if (value !== '' && value < min) {
            setIsInvalid(true);
            return;
        }

        if (value !== '' && value > max) {
            setIsInvalid(true);
            return;
        }

        setIsInvalid(false);
    }

    const handleBlur = (e) => {
        const value = e.target.value;
        setIsFocused(value === '');
        check(value);
    };

    const handleChange = (event) => {
        event.preventDefault();
        callback && callback(event.target.value);
    };

    return (
        <div>
            <input
                type="number"
                className={`border rounded-md px-2 py-4 w-full shadow-md ${isInvalid ? 'border-red-500' : 'border-gray-300'
                    }`}
                // className={clsx(
                //     'border rounded-md p-2 py-4 w-full shadow-md',
                //     {
                //         'border-red-500': isInvalid,
                //         'border-gray-300': isInvalid,
                //     }
                // )}
                placeholder={placeholder || 'Enter number here'}
                required={required}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                {...(min !== undefined ? { min } : {})}
                {...(max !== undefined ? { max } : {})}
                onChange={handleChange}
                value={value}
            />
        </div>
    );
};

const BaeLinkInput = ({ placeholder, callback, required = false, value = '' }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    /**
     * Regex for URL validation
     * valid urls:-
     * http://www.example.com, https://www.example.com, 
     * http://example.com, https://example.com, 
     * www.example.com
     */

    const check = (value) => {
        if (value !== '') {
            setIsInvalid(!URL_REGEX.test(value));
            return;
        }

        if (value === '' && required) {
            setIsInvalid(true);
            return;
        }

        if (value === '' && !required) {
            setIsInvalid(false);
            return;
        }

        setIsInvalid(false);
    }

    useEffect(() => {
        check(value);
    }, [value]);

    const handleBlur = (e) => {
        const value = e.target.value;
        check(value);
    };

    const handleChange = (event) => {
        event.preventDefault();
        check(event.target.value);
        callback && callback(event.target.value);
    };

    return (
        <div className='relative'>
            <input
                type="url"
                className={`border rounded-md p-2 py-4 w-full shadow-md ${isInvalid ? 'border-red-500' : 'border-gray-300'
                    }`}
                // className={clsx(
                //     'border rounded-md p-2 py-4 w-full shadow-md',
                //     {
                //         'border-red-500': isInvalid,
                //         'border-gray-300': isInvalid,
                //     }
                // )}
                placeholder={placeholder || 'Enter URL here'}
                required={required}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                onChange={handleChange}
                value={value}
            />
            {!isInvalid && (
                <FaCheck className="absolute right-4 top-1/2 transform -translate-y-1/2 text-baeonn-500" />
            )}
            {isInvalid && (
                <FaTimes className="absolute right-4 top-1/2 transform -translate-y-1/2 text-red-500" />
            )}
        </div>
    );
};

const BaeDateInput = ({ label, value, placeholder, min, callback }) => {
    const handleFocus = (event) => {
        event.target.type = 'date';
    };

    const handleChange = (event) => {
        event.preventDefault();;
        callback && callback(event.target.value);
    };

    return (
        <div className="relative">
            <label>{label}</label>
            <div className="relative">
                <input
                    className='w-full p-2 py-4 border rounded-md border-gray-300 cursor-pointer placeholder-transparent shadow-md'
                    type="date"
                    value={value}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onClick={(event) => event.target.showPicker()}
                    {...(min !== undefined ? { min } : {})}
                />
                <span className="pr-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none">
                    {placeholder}
                </span>
            </div>
        </div>
    );
};

const BaeHeading = ({ children, size = 'text-3xl', className, style }) => {
    return <span className={`${size} font-bold text-gray-900 ${className}`} style={{ ...style }}>{children}</span>;
};

const BaeSmallText = ({ children, className, style }) => {
    return <span className={`text-sm text-gray-500 ${className}`}
        style={{ ...style }}>{children}</span>;
};

const BaeButton = ({ children, disable, onClick, className, type = 'primary' }) => {
    const primary = 'w-full bg-baeonn-700 active:bg-baeonn-600 text-white py-4 rounded-md shadow-md transition-colors duration-200 overflow-hidden';
    const secondary = 'w-full bg-[#f17f3d] active:bg-[#f6b07b] text-white py-4 rounded-md shadow-md transition-colors duration-200 overflow-hidden';
    const danger = 'w-full bg-red-600 active:bg-red-700 text-white py-4 rounded-md shadow-md transition-colors duration-200 overflow-hidden';
    const small = 'w-1/3 text-sm bg-baeonn-800 active:bg-baeonn-600 text-white py-2 rounded-md shadow-md transition-colors duration-200 overflow-hidden flex items-center justify-center';

    let types = {
        primary,
        secondary,
        danger,
        small
    }

    let useClass = types[type];

    return (
        <button
            className={`${useClass} ${disable ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
            onClick={!disable ? onClick : undefined}
            disabled={disable}
        >
            {children}
        </button>
    );
};

const BaeSuccessText = ({ children }) => {
    return (
        <div className="bg-baeonn-100 text-black p-4 py-4 rounded-md flex items-center">
            <FaCheck className="bg-baeonn-500 text-white rounded-full flex-shrink-0 p-1 mr-2" />
            {children}
        </div>
    );
};

const BaeWarningText = ({ children }) => {
    return (
        <div className="bg-baeonn-100 text-white p-4 py-4 rounded-md flex items-center">
            <FaExclamation className="bg-orange-500 text-white rounded-full flex-shrink-0 p-1 mr-2" />
            {children}
        </div>
    );
};

const BaeErrorText = ({ children }) => {
    return (
        <div className="bg-red-100 text-black p-4 py-4 rounded-md flex items-center">
            <FaTimes className="bg-red-500 text-white rounded-full flex-shrink-0 p-1 mr-2" />
            {children}
        </div>
    );
};

const BaeHorizontalLine = () => {
    return <hr className="border-gray-300 my-4" />;
}

const BaeTermsAndConditionButton = () => {
    return (
        <BaeSmallText className="text-gray-700">By clicking this, I agree to Baeonn Terms & Conditions and Privacy Policy.</BaeSmallText>
    );
}

const BaeSmallTextButton = ({ children, onClick }) => {
    return (
        <button onClick={onClick} className="text-sm text-baeonn-700">
            {children}
        </button>
    );
};

const BaeSmallButton = ({ children, disable, onClick }) => {
    return (
        <button
            className={`bg-baeonn-800 text-white py-4 rounded-md shadow-md transition-colors duration-200 relative overflow-hidden ${disable ? 'opacity-50 cursor-not-allowed' : 'active:bg-baeonn-600'}`}
            onClick={!disable ? onClick : undefined}
            disabled={disable}
        >
            {children}
        </button>
    );
};

const BaeCustomRadioButton = ({ options, field, callback, selected = null }) => {
    const getValue = (option) => {
        return typeof option === 'string' ? option : option[field];
    }

    const [selectedOption, setSelectedOption] = useState(getValue(options[0]));

    useEffect(() => {
        if (selected) {
            setSelectedOption(getValue(selected));
        }
    }, [selected]);

    const onChangeHandler = (option) => {
        setSelectedOption(getValue(option));
        callback && callback(option);
    }

    return (
        <div className="flex justify-center w-full">
            <div className="flex w-full">

                {
                    options.map((option, index) => {
                        const isFirst = index === 0 ? 'rounded-l-md' : '';
                        const isLast = index === options.length - 1 ? 'rounded-r-md' : '';

                        return <label
                            className={`border flex-1 flex items-center justify-center p-4 cursor-pointer ${selectedOption === getValue(option)
                                ? 'bg-baeonn-800 text-white'
                                : 'bg-gray-200 text-black'
                                } ${isFirst} ${isLast}`}
                            onClick={() => onChangeHandler(option)}
                            key={index}
                        >
                            <input
                                type="radio"
                                className="hidden"
                                name="custom-radio-type"
                                value={getValue(option)}
                                checked={selectedOption === getValue(option)}
                                onChange={() => onChangeHandler(option)}
                            />
                            <span className="ml-2">
                                {getValue(option)}
                            </span>
                        </label>
                    })
                }

            </div>
        </div >
    );
};

const BaeToggle = ({ onToggle, isOn, label }) => {
    return (
        <label className="inline-flex items-center cursor-pointer">
            <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={isOn}
                onChange={onToggle}
            />
            <div className="relative w-9 h-5 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-baeonn-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-baeonn-600"></div>
            {label && <span className="ml-2 text-gray-700">{label}</span>}
        </label>
    );
};

const BaeDetailBox = ({ title, value }) => {
    return (
        <>
            <div className="flex min-w-full flex-1 flex-col gap-2 rounded-xl p-6 border border-[#344d65]">
                <p className="text-black text-base font-medium leading-normal">
                    {title}
                </p>
                <p className="text-black tracking-light text-2xl font-bold leading-tight">
                    {value}
                </p>
            </div>
        </>
    );
};

const BaeLoadingButton = ({ isLoading, children, disable, onClick, className }) => {
    return (
        <button
            className={`w-full bg-baeonn-800 text-white py-4 rounded-md shadow-md transition-colors duration-200 relative overflow-hidden ${disable || isLoading ? 'opacity-50 cursor-not-allowed' : 'active:bg-baeonn-600'} ${className}`}
            onClick={!disable && !isLoading ? onClick : undefined}
            disabled={disable || isLoading}
        >
            {isLoading && (
                <div className="flex justify-center items-center absolute inset-0">
                    <svg className="h-5 w-5 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
            <span className={isLoading ? 'opacity-0' : ''}>{children}</span>
        </button>
    );
};

const BaeLinkButton = ({ children, value, className }) => {
    return (
        <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            className={`text-center inline-block bg-green-600 text-white py-4 px-4 rounded-md shadow-md transition-colors duration-200 ${className}`}
        >
            {children}
        </a>
    );
}

const BaeEmailInput = ({ placeholder, callback, required = false, value = '' }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    /**
     * Regex for URL validation
     * valid urls:-
     * http://www.example.com, https://www.example.com, 
     * http://example.com, https://example.com, 
     * www.example.com
     */

    const check = (value) => {
        if (value !== '') {
            setIsInvalid(!EMAIL_REGEX.test(value));
            return;
        }

        if (value === '' && required) {
            setIsInvalid(true);
            return;
        }

        if (value === '' && !required) {
            setIsInvalid(false);
            return;
        }

        setIsInvalid(false);
    }

    useEffect(() => {
        check(value);
    }, [value]);

    const handleBlur = (e) => {
        const value = e.target.value;
        check(value);
    };

    const handleChange = (event) => {
        event.preventDefault();
        check(event.target.value);
        callback && callback(event.target.value);
    };

    return (
        <div className='relative'>
            <input
                type="url"
                className={`border rounded-md p-2 py-4 w-full shadow-md ${isInvalid ? 'border-red-500' : 'border-gray-300'
                    }`}
                placeholder={placeholder || 'Enter URL here'}
                required={required}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                onChange={handleChange}
                value={value}
            />
            {!isInvalid && (
                <FaCheck className="absolute right-4 top-1/2 transform -translate-y-1/2 text-baeonn-500" />
            )}
            {isInvalid && (
                <FaTimes className="absolute right-4 top-1/2 transform -translate-y-1/2 text-red-500" />
            )}
        </div>
    );
};

const BaeTab = ({ tabs, onSelect, isSpaced = false }) => {
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        onSelect(tab);
    };

    return (
        <div className="flex border-b mb-4">
            {tabs.map((tab) => (
                <button
                    key={tab}
                    className={`py-4 px-4 ${isSpaced ? 'flex-1 text-center' : ''} ${activeTab === tab ? 'border-b-2 border-baeonn-800 bg-baeonn-100 rounded-tl-md rounded-tr-md' : ''}`}
                    onClick={() => handleTabClick(tab)}
                >
                    {tab}
                </button>
            ))}
        </div>
    );
};

const BaeCopyTextInput = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        });
    };

    return (
        <div className="grid grid-cols-12">
            <input
                type="text"
                value={text}
                readOnly
                className="border p-2 rounded w-full col-span-9 rounded-tr-none rounded-br-none bg-baeonn-50 focus:outline-none"
            />
            <button
                onClick={handleCopy}
                className="bg-baeonn-800 text-white p-2 rounded col-span-3 rounded-tl-none rounded-bl-none"
            >
                {copied ? 'Copied!' : 'Copy'}
            </button>
        </div>
    );
};

export {
    BaeTextInput,
    BaeParagraphInput,
    BaeNumberInput,
    BaeLinkInput,
    BaeDateInput,
    BaeHeading,
    BaeSmallText,
    BaeButton,
    BaeSuccessText,
    BaeHorizontalLine,
    BaeTermsAndConditionButton,
    BaeSmallTextButton,
    BaeSmallButton,
    BaeCustomRadioButton,
    BaeErrorText,
    BaeWarningText,
    BaeToggle,
    BaeDetailBox,
    BaeLoadingButton,
    BaeLinkButton,
    BaeEmailInput,
    BaeTab,
    BaeCopyTextInput
};
